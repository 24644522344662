import PropTypes from 'prop-types'
import React from 'react'
import { Container, Stack } from '@mui/material'

export const DZ_ColoredSection = ({ children, color }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        alignItems: 'center',
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Stack>{children}</Stack>
    </Container>
  )
}

DZ_ColoredSection.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
}
