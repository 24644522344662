import { Stack } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { DZ_GotoPage, Typo26, Typo32 } from '../../'

export const ProjectText = ({ excerpt, slug, title, maxWidth }) => {
  return (
    <Stack alignItems={'start'} justifyContent={'center'} spacing={5}>
      <Typo26>{title}</Typo26>
      <Typo32 sx={{ maxWidth, mb: 2 }}>{excerpt}</Typo32>

      <DZ_GotoPage href={`/projekte/${slug}`} title={'Zum Projekt'} />
    </Stack>
  )
}

ProjectText.defaultProps = {
  maxWidth: 550
}

ProjectText.propTypes = {
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.number
}
