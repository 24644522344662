import React from 'react'
import PropTypes from 'prop-types'

const lineWidth = '5px' 
const overflowLineDist = '50px'

export const VerticalLine = () => {
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}>
      <div style={{
        position: 'absolute',
        left: '50%',
        width: lineWidth,
        top: `-${overflowLineDist}`,
        bottom: `-${overflowLineDist}`,
        background: '#fff',
        transform: 'translateX(-50%)',
      }}>
      </div>
    </div>
  )
}

VerticalLine.propTypes = {
  sx: PropTypes.object
}
