import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'

export const ImageItem = ({ src }) => (
  <Box
    component={'img'}
    src={src}
    sx={{ objectFit: 'contain', overflow: 'hidden', width: 1 }}
  />
)

ImageItem.propTypes = {
  src: PropTypes.string.isRequired
}
