import { ListItem, ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SVG_Plektrum } from '../SVG/SVG_Plektrum'
import { Typo20 } from '../TYPO'

export const PlekListItem20 = ({ data }) => (
  <ListItem alignItems={'flex-start'}>
    <ListItemIcon sx={{ minWidth: 36 }}>
      <SVG_Plektrum />
    </ListItemIcon>

    <Typo20>{data}</Typo20>
  </ListItem>
)

PlekListItem20.propTypes = {
  data: PropTypes.string.isRequired
}
