import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import {
  DZ_GotoPage,
  SVGPic,
  Typo20,
  Typo26,
  Typo32,
  Typo43,
  Typo26Bold
} from '../../'
import { useOnScreen } from '../../../hooks'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const ConsultingMobile = ({ data }) => {
  const { description, ListItem, more, slogan, title } = data
  const ref = useRef()

  return (
    <Container sx={{ pt: 10, mb: 10 }}>
      <Stack alignItems={'center'}>
        <Typo20>{title}</Typo20>
        <Typo26Bold sx={{ mb: 1, textAlign: 'center' }}>{slogan}</Typo26Bold>
        <Typo20 sx={{ maxWidth: 966, mb: 10 }}>{description}</Typo20>

        <Stack
          direction={'row'}
          sx={{ overflowX: 'auto', overflowY: 'hidden', width: '100%' }}
        >
          {ListItem.map((item, key) => {
            const isOnScreen = useOnScreen(ref)
            const { background, description, icon } = item
            const { url } = icon.data.attributes
            const {
              url: bgUrl,
              width: bgWidth,
              height: bgHeight
            } = background.data.attributes

            const fixData = [
              { left: 0 - 650, top: 0 },
              { left: 412 - 650, top: 0 },
              { left: 720 - 650, top: -120 }
            ]

            return (
              <Stack
                ref={ref}
                key={key}
                sx={{ position: 'relative', height: 500 }}
              >
                <Box
                  component={'img'}
                  src={`${BASE_URL}${bgUrl}`}
                  sx={{
                    position: 'absolute',
                    left: fixData[key].left,
                    top: fixData[key].top,
                    width: bgWidth,
                    height: bgHeight
                  }}
                  className={isOnScreen ? `roll-in-left-${key + 1}` : ''}
                />

                <Stack
                  alignItems={'center'}
                  sx={{
                    position: 'absolute',
                    left: fixData[key].left,
                    top: 130,
                    width: bgWidth,
                    height: bgHeight
                  }}
                  className={isOnScreen ? `fade-in-${key + 1}` : ''}
                >
                  <SVGPic src={`${BASE_URL}${url}`} />

                  <Typo32
                    color={'primary'}
                    sx={{ maxWidth: 300, textAlign: 'center', mt: 2 }}
                  >
                    {description}
                  </Typo32>
                </Stack>
              </Stack>
            )
          })}
        </Stack>

        {/* mehr zu consulting */}
        <DZ_GotoPage href={'/consulting'} sx={{ mt: 10 }} title={more} />
      </Stack>
    </Container>
  )
}

ConsultingMobile.propTypes = {
  data: PropTypes.object.isRequired
}

const ConsultingDesktop = ({ data }) => {
  const { description, ListItem, more, slogan, title } = data
  const ref = useRef()

  return (
    <Container sx={{ pt: 10, mb: 10, overflow: 'hidden' }}>
      <Stack alignItems={'center'}>
        <Typo26>{title}</Typo26>
        <Typo43 sx={{ mb: 1 }}>{slogan}</Typo43>
        <Typo20 sx={{ maxWidth: 966, mb: 10, textAlign: 'center' }}>
          {description}
        </Typo20>

        <Stack direction={'row'}>
          {ListItem.map((item, key) => {
            const isOnScreen = useOnScreen(ref)
            const { background, description, icon } = item
            const { url } = icon.data.attributes
            const {
              url: bgUrl,
              width: bgWidth,
              height: bgHeight
            } = background.data.attributes

            const fixData = [
              { left: 0 - 650, top: 0 },
              { left: 412 - 650, top: 0 },
              { left: 720 - 650, top: -120 }
            ]

            return (
              <Stack
                ref={ref}
                key={key}
                sx={{ position: 'relative', height: 500 }}
              >
                <Box
                  component={'img'}
                  src={`${BASE_URL}${bgUrl}`}
                  sx={{
                    position: 'absolute',
                    left: fixData[key].left,
                    top: fixData[key].top,
                    width: bgWidth,
                    height: bgHeight
                  }}
                  className={isOnScreen ? `roll-in-left-${key + 1}` : ''}
                />

                <Stack
                  alignItems={'center'}
                  sx={{
                    position: 'absolute',
                    left: fixData[key].left,
                    top: 130,
                    width: bgWidth,
                    height: bgHeight
                  }}
                  className={isOnScreen ? `fade-in-${key + 1}` : ''}
                >
                  <SVGPic src={`${BASE_URL}${url}`} />

                  <Typo32
                    color={'primary'}
                    sx={{ maxWidth: 300, textAlign: 'center', mt: 2 }}
                  >
                    {description}
                  </Typo32>
                </Stack>
              </Stack>
            )
          })}
        </Stack>

        {/* mehr zu consulting */}
        <DZ_GotoPage href={'/consulting'} sx={{ mt: 10 }} title={more} />
      </Stack>
    </Container>
  )
}

ConsultingDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

export const Consulting = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD && <ConsultingDesktop data={data} />
  /* : <ConsultingMobile data={data} /> */
}

Consulting.propTypes = {
  data: PropTypes.object.isRequired
}
