//import { Box } from '@mui/material'
import React /*, { useEffect, useState } */ from 'react'
import { getPageTitle, parseDZData } from '../api'
import { DynamicZoneRenderer, PageWrapper } from '../components'
import { useContact } from '../hooks'
//import PLEK from '../img/projects.bg.left.svg'

export const PageContact = () => {
  const { data } = useContact()
  if (!data) return null

  parseDZData(data)

  const parsed = data[Object.keys(data)[0]]
  const title = getPageTitle(data)

  /*
  const Starfield = () => {
    const [stars, setStars] = useState([])

    const getRandomValue = (min, max) => Math.random() * (max - min) + min

    const createStars = () => {
      const newStars = Array.from({ length: 20 }, (_, index) => ({
        id: index,
        x: getRandomValue(0, window.innerWidth),
        y: getRandomValue(0, window.innerHeight),
        speed: getRandomValue(0.05, 0.4),
        size: getRandomValue(100, 300),
        rotation: getRandomValue(0, 360)
      }))
      setStars(newStars)
    }

    useEffect(() => {
      createStars()
    }, [])

    useEffect(() => {
      const moveStars = () => {
        const timeStep = 0.016
        setStars((prevStars) =>
          prevStars.map((star) => ({
            ...star,
            y: (star.y + star.speed * timeStep) % window.innerHeight
          }))
        )
        requestAnimationFrame(moveStars)
      }

      //const animationFrame = requestAnimationFrame(moveStars)
      //return () => cancelAnimationFrame(animationFrame)
      const animationInterval = setInterval(moveStars, 16.67) // Aktualisierung alle 16.67 Millisekunden für 60 FPS
      return () => clearInterval(animationInterval)
    }, [stars])

    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '50vh',
          overflow: 'hidden',
          backgroundColor: 'black'
        }}
      >
        {stars.map((star) => (
          <div
            key={star.id}
            style={{
              position: 'absolute',
              top: `${star.y}px`,
              left: `${star.x}px`
            }}
          >
            <Box
              component="img"
              src={PLEK}
              sx={{
                width: star.size,
                transform: `rotate(${star.rotation}deg)`
              }}
            />
          </div>
        ))}
      </div>
    )
  }
  */

  return (
    <PageWrapper data={{ title }}>
      <DynamicZoneRenderer data={parsed} />

      {/*<Starfield />*/}
    </PageWrapper>
  )
}
