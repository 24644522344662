import {
  Box,
  Container,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { DZ_GotoPage, SVG_BgProjects, Typo26, Typo43, Typo50 } from '../../'
import SVG_PLEKS_PROJECTS from '../../../img/pleks.projects.svg'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const GotoProjectButton = () => {
}

GotoProjectButton.propTypes = {
  caption: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

const HomeProjectsDesktop = ({ data }) => {
  const theme = useTheme()
  const { primary } = theme.palette
  const { contrastText: color } = primary
  const { headline, left, leftPic, more, right, rightPic, title } = data
  const { url: leftUrl } = leftPic.data.attributes
  const { url: rightUrl } = rightPic.data.attributes

  return (
    <Container sx={{ mt: 10, overflow: 'hidden' }}>
      <Stack
        alignItems={'center'}
        sx={{ mb: 10, position: 'relative', height: 1200 }}
      >
        <SVG_BgProjects />

        <Stack alignItems={'center'}>
          <Typo26 color={color} sx={{ zIndex: 15000, mt: 20 }}>
            {title}
          </Typo26>

          <Typo43 color={color} sx={{ zIndex: 15000, mb: 20 }}>
            {headline}
          </Typo43>

          <Stack
            direction={'row'}
            spacing={1}
            sx={{ position: 'relative', zIndex: 10001, width: 1300 }}
          >
        
            <Grid container>
              <Grid item xs={6} sx={{ height: 400 }}>
                <Box
                  component={'img'}
                  src={`${BASE_URL}${leftUrl}`}
                  sx={{
                    position: 'absolute',
                    left: -66,
                    top: -143,
                    zIndex: 5000
                  }}
                />
                <Box
                  component={'img'}
                  src={SVG_PLEKS_PROJECTS}
                  sx={{
                      zIndex: 12500,
                      position: 'absolute',
                      left: -468,
                      top: -388,
                      pointerEvents: 'none',
                      cursor: 'pointer'
                    }}
                />

                <Typo50
                  sx={{
                    color: 'white', // secondary.light,
                    zIndex: 15000,
                    maxWidth: 500,
                    position: 'absolute',
                    left: 40,
                    bottom: 30
                  }}
                >
                  {left}
                </Typo50>
              </Grid>

              <Grid item xs={6} sx={{ height: 400 }}>

                <Box
                  component={'img'}
                  src={`${BASE_URL}${rightUrl}`}
                  sx={{
                    position: 'absolute',
                    right: -205,
                    top: -259,
                    zIndex: 5000
                  }}
                />

                <Typo50
                  sx={{
                    color: 'white', //secondary.light,
                    zIndex: 15000,
                    maxWidth: 400,
                    textAlign: 'justify',
                    position: 'absolute',
                    right: 0
                  }}
                >
                  {right}
                </Typo50>
              </Grid>
            </Grid>
          </Stack>

          {/* zu allen Projekten */}
          <DZ_GotoPage
            href={'/projekte'}
            sx={{ zIndex: 15000, borderColor: color, color, mt: 18 }}
            title={more}
          />
        </Stack>
      </Stack>
    </Container>
  )
}

HomeProjectsDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

const HomeProjectsMobile = ({ data }) => {
  const theme = useTheme()
  const { primary, secondary } = theme.palette
  const { contrastText: color } = primary
  const { headline, left, leftPic, more, right, rightPic, title } = data
  const { url: leftUrl } = leftPic.data.attributes
  const { url: rightUrl } = rightPic.data.attributes

  return (
    <Container sx={{ mt: 10, overflow: 'hidden' }}>
      <Stack
        alignItems={'center'}
        sx={{ mb: 10, position: 'relative', height: 1200 }}
      >
        <SVG_BgProjects />

        <Stack alignItems={'center'}>
          <Typo26 color={color} sx={{ zIndex: 15000, mt: 20 }}>
            {title}
          </Typo26>

          <Typo43 color={color} sx={{ zIndex: 15000, mb: 20 }}>
            {headline}
          </Typo43>

          <Box
            component={'img'}
            src={SVG_PLEKS_PROJECTS}
            sx={{
              zIndex: 12500,
              position: 'absolute',
              top: 100,
              pointerEvents: 'none',
              cursor: 'pointer'
            }}
          />

          <Stack
            direction={'row'}
            spacing={1}
            sx={{ position: 'relative', zIndex: 10001, width: 1300 }}
          >
            <Grid container sx={{}}>
              <Grid item xs={6} sx={{ height: 400 }}>
                <Box
                  component={'img'}
                  src={`${BASE_URL}${leftUrl}`}
                  sx={{
                    position: 'absolute',
                    left: -66,
                    top: -143,
                    zIndex: 5000
                  }}
                />
                <Typo50
                  sx={{
                    color: secondary.light,
                    zIndex: 15000,
                    maxWidth: 500,
                    position: 'absolute',
                    left: 40,
                    bottom: 30
                  }}
                >
                  {left}
                </Typo50>
              </Grid>
              <Grid item xs={6} sx={{ height: 400 }}>
                <GotoProjectButton
                  src={`${BASE_URL}${rightUrl}`}
                  caption={right}
                />

                <Typo50
                  sx={{
                    color: secondary.light,
                    zIndex: 15000,
                    maxWidth: 400,
                    textAlign: 'justify',
                    position: 'absolute',
                    right: 0
                  }}
                >
                  {right}
                </Typo50>
              </Grid>
            </Grid>
          </Stack>

          {/* zu allen Projekten */}
          <DZ_GotoPage
            href={'/projekte'}
            sx={{ zIndex: 15000, borderColor: color, color, mt: 18 }}
            title={more}
          />
        </Stack>
      </Stack>
    </Container>
  )
}

HomeProjectsMobile.propTypes = {
  data: PropTypes.object.isRequired
}

export const HomeProjects = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD && <HomeProjectsDesktop data={data} />
  /* : <HomeProjectsMobile data={data} /> */
}

HomeProjects.propTypes = {
  data: PropTypes.object.isRequired
}
