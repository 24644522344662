import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { DZ_GotoPage, SVGPic, Typo26Bold, Typo32, Typo43, Typo20 } from '../../'
import { Content } from '../../wrapper/Content'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const AdvantageItem = ({ svg, description, maxWidth }) => {
  const theme = useTheme()
  const { contrastText: color } = theme.palette.primary

  return (
    <ImageListItem sx={{ alignItems: 'center', maxWidth, textAlign: 'center' }}>
      <SVGPic src={svg} />
      <Typo26Bold color={color}>{description}</Typo26Bold>
    </ImageListItem>
  )
}

AdvantageItem.defaultProps = {
  maxWidth: 320
}

AdvantageItem.propTypes = {
  svg: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  maxWidth: PropTypes.number
}

const HomeAdvantagesDesktop = ({ data }) => {
  const { ListItem, more, slogan, title, upperBgImage } = data
  const theme = useTheme()
  const { primary } = theme.palette
  const { contrastText: color } = primary
  const { url } = upperBgImage.data.attributes

  return (
    <Container
      sx={{
        height: 1100,
        overflow: 'hidden',
        width: 1,
        p: '0 !important',
        //        background: 'blue',
        background: {
          xs: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`,
          sm: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`,
          md: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`,
          lg: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`,
          xl: 'none'
        }
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        <Box
          component={'img'}
          draggable={false}
          src={`${BASE_URL}${url}`}
          sx={{
            display: {
              xl: 'block',
              lg: 'none',
              md: 'none',
              sm: 'none',
              xs: 'none'
            },
            left: 0,
            position: 'absolute',
            top: 0,
            zIndex: 10001,
            width: 1,
            objectFit: 'contain'
          }}
        />
      </Stack>

      <Stack alignItems={'center'} sx={{ mt: 20, mb: 10, textAlign: 'center' }}>
        <Typo32 sx={{ zIndex: 10002 }} color={color}>
          {title}
        </Typo32>

        <Typo43 sx={{ zIndex: 10003 }} color={color}>
          {slogan}
        </Typo43>

        <ImageList
          cols={3}
          sx={{ 
            zIndex: 10004, 
            mt: { xl: 10, md: 5 },
            overflow: 'hidden'
          }}
          gap={48}
        >
          {ListItem.map((item, key) => {
            const { description, icon } = item
            const { url } = icon.data.attributes
            const src = `${BASE_URL}${url}`

            return (
              <AdvantageItem key={key} svg={src} description={description} />
            )
          })}
        </ImageList>

        {/* mehr zum Prinzip dahinter */}
        <DZ_GotoPage
          color={'secondary'}
          href={'/das-prinzip'}
          sx={{
            zIndex: 10005,
            borderColor: color,
            color,
            mt: { xl: 3.6, md: 1 },
            '&:hover': {
              borderColor: primary.contrastText,
              bgcolor: primary.contrastText,
              color: primary.main
            }
          }}
          title={more}
        />
      </Stack>
    </Container>
  )
}

HomeAdvantagesDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

const HomeAdvantagesMobile = ({ data }) => {
  const { ListItem, more, slogan, title, upperBgImage } = data
  const theme = useTheme()
  const { primary } = theme.palette
  const { contrastText: color } = primary
  const { url } = upperBgImage.data.attributes
  const isGtXs = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Container
      sx={{
        overflow: 'hidden',
        width: 1,
        p: '0 !important',
        background: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`
      }}
    >
      <Content>
        <Stack sx={{ position: 'relative' }}>
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${url}`}
            sx={{
              display: {
                xl: 'block',
                lg: 'none',
                md: 'none',
                sm: 'none',
                xs: 'none'
              },
              left: -670,
              position: 'absolute',
              top: 0,
              zIndex: 10001,
              width: 1400,
              objectFit: 'cover'
            }}
          />
        </Stack>
  
        <Stack alignItems={'center'} sx={{ mt: 5, mb: 10, textAlign: 'center' }}>
          <Typo20 sx={{ zIndex: 10002 }} color={color}>
            {title}
          </Typo20>
  
          <Typo26Bold sx={{ zIndex: 10003 }} color={color}>
            {slogan}
          </Typo26Bold>
  
          <ImageList
            gap={32}
            cols={isGtXs ? 2 : 1}
            sx={{ 
              zIndex: 10004, 
              mt: 4,
              overflow: 'hidden'
            }}
          >
            {ListItem.map((item, key) => {
              const { description, icon } = item
              const { url } = icon.data.attributes
              const src = `${BASE_URL}${url}`
  
              return (
                <AdvantageItem key={key} svg={src} description={description} />
              )
            })}
          </ImageList>
  
          {/* mehr zum Prinzip dahinter */}
          <DZ_GotoPage
            color={'secondary'}
            href={'/das-prinzip'}
            sx={{
              zIndex: 10005,
              borderColor: color,
              color,
              mt: { xl: 3.6, md: 1 },
              '&:hover': {
                color: primary.main,
                borderColor: primary.contrastText,
                bgcolor: primary.contrastText
              }
            }}
            title={more}
          />
        </Stack>
      </Content>
    </Container>
  )
}

HomeAdvantagesMobile.propTypes = {
  data: PropTypes.object.isRequired
}

export const HomeAdvantages = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <HomeAdvantagesDesktop data={data} />
  ) : (
    <HomeAdvantagesMobile data={data} />
  )
}

HomeAdvantages.propTypes = {
  data: PropTypes.object.isRequired
}
