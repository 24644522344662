import { ListItem, ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SVG_Plektrum } from '../SVG/SVG_Plektrum'
import { Typo26 } from '../TYPO'

export const PlekListItem26 = ({ data }) => (
  <ListItem alignItems={'flex-start'}>
    <ListItemIcon sx={{ minWidth: 36 }}>
      <SVG_Plektrum />
    </ListItemIcon>

    <Typo26>{data}</Typo26>
  </ListItem>
)

PlekListItem26.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ])
}
