import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

export const SVG_Plektrum = ({ width, height }) => {
  const theme = useTheme()
  const { primary } = theme.palette
  const [id] = useState(_uniqueId('plektrum'))

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <linearGradient
          id={'linear-gradient'}
          x1={1}
          x2={0.152}
          y2={1}
          gradientUnits={'objectBoundingBox'}
        >
          <stop offset={0} stopColor={primary.light} />
          <stop offset={0.5} stopColor={primary.main} />
          <stop offset={1} stopColor={primary.dark} />
        </linearGradient>
        <clipPath id={`${id}-clip-path`}>
          <rect width={width} height={height} fill={'url(#linear-gradient)'} />
        </clipPath>
      </defs>
      <g clipPath={'url(#`${id}-clip-path`)'}>
        <path
          d="M9.764,0A3.651,3.651,0,0,0,7.237,1.065,19.478,19.478,0,0,0,4.274,4.67,32.544,32.544,0,0,0,.582,11.441,8.8,8.8,0,0,0,0,14.321v.061c.019,3.381,3.1,4.448,6.765,4.458h.1a25.083,25.083,0,0,0,3.181-.23,22.41,22.41,0,0,0,7.39-2.129c2.07-1.129,3.411-2.711,3.417-4.922v-.031a6.375,6.375,0,0,0-.16-1.368A14.074,14.074,0,0,0,16.638,3.7,16.412,16.412,0,0,0,13.015.989,6.954,6.954,0,0,0,10.008.006Q9.9,0,9.792,0Z"
          fill={'url(#linear-gradient)'}
        />
      </g>
    </svg>
  )
}

SVG_Plektrum.defaultProps = {
  width: 20.856,
  height: 18.839
}

SVG_Plektrum.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
