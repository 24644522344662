// modules
import { Container, Paper, Stack, TextField, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// scripts
import { getSession, isValidEmail } from '../api'
import { useMainMenu, useSocialNetworks, useSubMenu } from '../hooks'
import { DZ_GotoPage, SVG_BgContactBar, SVG_KarmaLogo, SVGPic, Typo26, Typo32, Typo43 } from '.'
import { Content } from './wrapper/Content'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

// menus

const BottomMenu = () => {
  const { data } = useMainMenu()

  return data?.data.map((item, key) => {
    const { title, url: to } = item.attributes
    const style = {
      color: '#000000',
      fontSize: 26,
      marginBottom: '20px',
      textDecoration: 'none'
    }

    return (
      <Link key={key} style={style} to={to}>
        {title}
      </Link>
    )
  })
}

const FooterMenu = () => {
  const theme = useTheme()
  const { grayscale } = theme.palette
  const { data } = useSubMenu()

  return data?.data.map((item, key) => {
    const { title, url: to } = item.attributes
    const style = {
      color: grayscale[600],
      fontSize: 26,
      textDecoration: 'none'
    }

    return (
      <Link key={key} style={style} to={to}>
        {title}
      </Link>
    )
  })
}

const SocialMenu = () => {
  const { data } = useSocialNetworks()

  return data?.data.map((item, key) => {
    const { icon, url: to } = item.attributes
    const { url, width: size } = icon.data.attributes
    const src = `${BASE_URL}${url}`

    return (
      <a href={to} key={key} rel={'noreferrer'} target={'_blank'}>
        <SVGPic key={key} src={src} size={size} />
      </a>
    )
  })
}

// gui

const ContactBar = () => {
  const theme = useTheme()
  const { grayscale, primary } = theme.palette

  const { pathname } = useLocation()
  const bgColor = pathname.includes('/projekte/')
    ? primary.dark
    : (
      pathname.includes('/geg')
        ? grayscale[100]
        : 'white'
    )

  return (
    <Container
      sx={{
        backgroundColor: bgColor,
        height: 352,
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <Content>
      <SVG_BgContactBar
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 0,
          objectFit: 'cover',
          width: '100%'
        }}
      />

      <Stack alignItems={'center'} sx={{ pt: 7.2 }}>
        <Typo43
          color={primary.contrastText}
          sx={{ textAlign: 'center', maxWidth: 670, zIndex: 1, width: 1 }}
        >
          Starten Sie jetzt mit der Optimierung Ihrer Gebäude
        </Typo43>

        <DZ_GotoPage
          href={'/kontakt'}
          sx={{
            borderColor: primary.contrastText,
            color: primary.contrastText,
            mt: 3.6,
            zIndex: 1,
            '&:hover': {
              color: '#A81398',
              borderColor: primary.contrastText,
              backgroundColor: primary.contrastText
            }
          }}
          title={'Kontakt'}
        />
      </Stack>
      </Content>
    </Container>
  )
}

const ContactBarMobile = () => {
  const theme = useTheme()
  const { primary } = theme.palette
  const { contrastText } = primary
  // const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Container
      maxWidth={false}
      sx={{
        width: 1,
        height: 352,
        overflow: 'hidden',
        position: 'relative',
        p: 0,
        backgroundColor: primary.main,
        mt: '0.8px'
      }}
    >
      <Content>
      {/*<SVG_BgContactBar
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 0,
          width: window.innerWidth,
          height: 400
          //objectFit: ''
        }}
      />
      */}
      <Stack alignItems={'center'} sx={{ pt: 7.2 }}>
        <Typo32
          color={contrastText}
          sx={{ textAlign: 'center', maxWidth: 670, zIndex: 1, width: 1 }}
        >
          Starten Sie jetzt mit der Optimierung Ihrer Gebäude
        </Typo32>

        <DZ_GotoPage
          href={'/kontakt'}
          sx={{
            borderColor: primary.contrastText,
            color: primary.contrastText,
            mt: 3.6,
            zIndex: 1,
            '&:hover': {
              color: primary.main,
              borderColor: primary.contrastText,
              backgroundColor: primary.contrastText
              }
          }}
          title={'Kontakt'}
        />
      </Stack>
      </Content>
    </Container>
  )
}

const FooterBar = () => {
  const theme = useTheme()
  const { grayscale } = theme.palette
  const [email, setEmail] = useState('')
  const [canSubscribe, setCanSubscribe] = useState(false)

  const bearer = getSession('token')

  useEffect(() => setCanSubscribe(isValidEmail(email)), [email])

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{
        position: 'relative',
        width: 1,
        height: 308,
        backgroundColor: grayscale[100]
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          left: { xl: 280, md: 80 },
          top: 63,
          width: 100,
          height: 100
        }}
      >
        <SVG_KarmaLogo />
      </Stack>

      <Stack
        sx={{
          position: 'absolute',
          left: { xl: 555, md: 355 },
          top: 53,
          width: 500,
          maxHeight: 250,
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        <BottomMenu />
      </Stack>

      <Stack
        sx={{
          display: 'none',
          position: 'absolute',
          right: 0,
          mr: { xl: 25, md: 15 },
          mt: 5
        }}
      >
        <Typo26 sx={{ mb: 1 }}>Newsletter abonnieren</Typo26>
        <TextField
          error={!canSubscribe && email !== ''}
          onChange={(event) => setEmail(event.target.value)}
          onKeyDown={(event) => {
            if (event.keyCode === 13 && isValidEmail(email)) {
              const { REACT_APP_BASE_URL: BASE_URL } = process.env
              fetch(
                `${BASE_URL}/api/newsletter-subscribers`,
                {
                  method: 'POST',
                  headers: {
                    accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`
                  },
                  body: `{"email": "${email}"}`
                }
              )
                .then(() => {
                  setEmail('')
                  setCanSubscribe(false)
                })
                .catch((err) => {
                  console.error('error', err)
                })
            }
          }}
          placeholder={'E-Mail'}
          sx={{ width: 400 }}
          value={email}
        />
      </Stack>
    </Stack>
  )
}

const FooterBarMobile = () => {
  const theme = useTheme()
  const { grayscale } = theme.palette

  return (
    <Stack
      sx={{
        width: 1,
        backgroundColor: grayscale[100],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ display: 'none' }}>
        <Typo26 sx={{ my: 2 }}>Newsletter abonnieren</Typo26>
        <TextField placeholder={'E-Mail'} />
      </div>

      <BottomMenu />

      <Stack
        sx={{
          width: 100,
          height: 100,
          display: 'flex',
          alignItems: 'center',
          my: 2
        }}
      >
        <SVG_KarmaLogo />
      </Stack>
    </Stack>
  )
}

const BottomBar = () => {
  const theme = useTheme()
  const { grayscale } = theme.palette
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Stack
      alignItems={'center'}
      direction={isGtMD ? 'row' : 'column'}
      justifyContent={'space-between'}
      sx={{
        backgroundColor: grayscale[200],
        height: { md: 96 },
        px: 25,
        width: 1
      }}
    >
      <Stack direction={isGtMD ? 'row' : 'column'} spacing={isGtMD ? 10 : 3}>
        <FooterMenu />
      </Stack>

      <Stack
        alignItems={'center'}
        direction={'row'}
        spacing={1.7}
        sx={{ mt: 1 }}
      >
        <SocialMenu />
      </Stack>
    </Stack>
  )
}

const BottomBarMobile = () => {
  const theme = useTheme()
  const { grayscale } = theme.palette

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{ backgroundColor: grayscale[200], width: 1, py: 5 }}
    >
      <Stack spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
        <FooterMenu />
      </Stack>

      <Stack alignItems={'center'} direction={'row'} spacing={2} sx={{ my: 3 }}>
        <SocialMenu />
      </Stack>
    </Stack>
  )
}

// component

export const Footer = () => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Paper elevation={0}>
      {isGtMD ? <ContactBar /> : <ContactBarMobile />}
      {isGtMD ? <FooterBar /> : <FooterBarMobile />}
      {isGtMD ? <BottomBar /> : <BottomBarMobile />}
    </Paper>
  )
}
