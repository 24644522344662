// modules
import {
  Box,
  Container,
  Paper,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
// scripts
import {
  ProjectInfo,
  DZ_TextBlock,
  TheAchievement,
  TheExecution,
  //SVG_BgProjectMask,
  Typo20RTF,
  Typo32,
  Header,
  Footer
} from '../components'
import { useProjects } from '../hooks'
// assets
import BLUBFOO from '../img/single.project.mask.svg'
import BPM_M from '../img/mobile/BigPic.Mobile.M.svg'

//const BLUBFOO = SVG_BgProjectMask

const { REACT_APP_BASE_URL: BASE_URL } = process.env

export const PageSingleProject = () => {
  const theme = useTheme()
  const isGtSM = useMediaQuery(theme.breakpoints.up('sm'))
  const { slug } = useParams()
  const { data } = useProjects()

  if (!data) return null
  const { ProjectsContent } = data

  const singleProjectData = ProjectsContent.find(
    (f) => f.__component === 'common.projects'
  ).projects.data.find((f) => f.attributes.slug === slug)

  const {
    author,
    cite,
    description,
    excerpt,
    image,
    title,
    TheExecution: executionData,
    TheAchievement: achievementData
  } = singleProjectData.attributes
  const { url } = image.data.attributes

  return (
    <Paper elevation={0}>
      <Header />

      {/* BigPic (alike) */}
      <Stack sx={{ position: 'relative', _minHeight: 866 }}>
        {isGtSM ? (
          <Box
            component={'img'}
            src={`${BASE_URL}${url}`}
            sx={{
              mt: 15,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: 1,
              //height: 'auto', //866,
              //maxHeight: 866,
              maskImage: `url(${BLUBFOO})`,
              WebkitMaskImage: `url(${BLUBFOO})`,
              maskSize: 2600,
              maskRepeat: 'no-repeat',
              maskPosition: '55% 0%',
              zIndex: 150
            }}
          />
        ) : (
          <Stack sx={{ mb: 10 }}>
            <Box
              component={'img'}
              src={`${BASE_URL}${url}`}
              sx={{
                mt: 7,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: 1,
                height: 230,
                maskImage: `url(${BLUBFOO})`,
                WebkitMaskImage: `url(${BLUBFOO})`,
                maskSize: 480,
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
                zIndex: 150
              }}
            />
            <Box
              component={'img'}
              src={BPM_M}
              sx={{
                left: 0,
                top: 230,
                objectFit: 'fill',
                position: 'absolute',
                width: 1,
                height: 'auto',
                overflowX: 'hidden',
                zIndex: 1500
              }}
            />
            <Typo32
              sx={{
                left: 20,
                top: 250,
                position: 'absolute',
                color: 'white',
                zIndex: 15000
              }}
            >
              Projekt: {title}
            </Typo32>
          </Stack>
        )}
      </Stack>
      {/* BigPic (alike) */}

      <Stack alignItems={'center'}>
        {/* TextBlock */}
        <DZ_TextBlock data={{ slogan: excerpt, title }} />

        {/* ProjectInfo */}
        <ProjectInfo data={{ description, image }} />

        {/* Die Umsetzung */}
        <TheExecution data={executionData} />

        {/* Der Erfolg */}
        <TheAchievement data={achievementData} />

        <Container
          maxWidth={false}
          sx={{
            background: '#6E23BA',
            color: 'white',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: 1
          }}
        >
          <Stack sx={{ pb: 4 }}>
            <Typo32>{cite}</Typo32>
            <Stack sx={{ alignItems: 'end', width: 1 }}>
              <Typo20RTF sx={{ alignItems: 'end', mt: 3, textAlign: 'right' }}>
                {author}
              </Typo20RTF>
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Footer />
    </Paper>
  )
}
