import { Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import { getPageTitle, parseDZData } from '../api'
import {
  BigPic,
  DZ_ColoredSection,
  JobOffers,
  PageWrapper,
  DZ_SectionHeader,
  DZ_TextBlock,
  Typo20,
  DZ_WhatToExpect
} from '../components'
import { useCareer } from '../hooks'

export const PageCareer = () => {
  const [sectionBgColor, setSectionBgColor] = useState('transparent')
  const [selected, setSelected] = useState(0)

  const { data } = useCareer()
  if (!data) return null

  parseDZData(data)

  const parsed = data[Object.keys(data)[0]]
  const title = getPageTitle(data)

  return (
    <PageWrapper data={{ title }}>
      {parsed.map((item, key) => {
        const {
          __component,
          color,
          description,
          jobs,
          maxWidth,
          slogan,
          text,
          title
        } = item

        const scrollToAnchor = () => {
          const anchor = document.querySelector('#jobs')

          if (anchor) anchor.scrollIntoView({ behavior: 'smooth' })
        }

        const handleSwitchOffer = (selected) => setSelected(selected)

        switch (__component) {
          case 'common.big-pic':
            return <BigPic data={item} key={key} />

          case 'common.text-block':
            return (
              <DZ_TextBlock
                data={{ description, slogan, title }}
                key={key}
                sloganWidth={700}
                sx={{ maxWidth: 812, px: 2 }}
              />
            )

          case 'common.goto-page':
            return (
              <Button key={key} onClick={scrollToAnchor}>
                {title}
              </Button>
            )

          case 'common.section-header':
            if (key === 5) {
              return (
                <DZ_ColoredSection color={sectionBgColor} key={key}>
                  <DZ_SectionHeader title={title} />
                </DZ_ColoredSection>
              )
            }

            return <DZ_SectionHeader title={title} key={key} />

          case 'common.what-to-expect':
            return <DZ_WhatToExpect data={item} key={key} />

          case 'common.section-background':
            if (color !== sectionBgColor) setSectionBgColor(color)

            return <Stack key={key} id={'jobs'} />

          case 'common.jobs':
            return (
              <DZ_ColoredSection color={sectionBgColor} key={key}>
                <JobOffers
                  data={jobs.data}
                  onChange={handleSwitchOffer}
                  selected={selected}
                />
              </DZ_ColoredSection>
            )

          case 'common.simple-text':
            return (
              <DZ_ColoredSection color={sectionBgColor} key={key}>
                <Typo20 sx={{ maxWidth, color: '#707070', my: 5 }}>
                  {text}
                </Typo20>
              </DZ_ColoredSection>
            )

          default:
            return null
        }
      })}
    </PageWrapper>
  )
}
