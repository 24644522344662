import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const SVG_BgHeader = ({ width, height, ...rest }) => {
  const theme = useTheme()
  const { grayscale } = theme.palette

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
    >
      <defs>
        <clipPath id={'clip-path-header'}>
          <rect width={1000} height={904} fill={'#000000'} />
        </clipPath>
      </defs>
      <g
        clipPath={'url(#clip-path-header)'}
        opacity={0.2}
        transform={'translate(218.583) rotate(14)'}
      >
        <path
          d={
            'M468.259,0C419.347.167,384.209,18.8,347.09,51.06,293.135,97.968,244.63,166.19,204.964,223.97,141.009,317.134,66.5,440.85,27.932,548.68,8.97,601.721.21,647.483,0,686.808v2.925c.907,162.127,148.839,213.324,324.449,213.8h4.927c49.615-.138,101.4-4.253,152.569-11.009,113.549-14.986,251.386-45.9,354.405-102.083,99.3-54.144,163.593-130.022,163.883-236.06v-1.488a305.744,305.744,0,0,0-7.678-65.6c-26.06-117-112.649-230.871-194.623-310.073C745.11,126.2,689.137,82.54,624.2,47.446,578.3,22.642,533.89,3.186,479.993.3Q474.714.015,469.638,0Z'
          }
          fill={grayscale[600]}
        />
      </g>
    </svg>
  )
}

SVG_BgHeader.defaultProps = {
  width: 1189,
  height: 1119
}

SVG_BgHeader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
