import PropTypes from 'prop-types'
import React from 'react'

export const SVG_Dot = ({ fill, size, stroke }) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
  >
    <g fill={fill} stroke={stroke} strokeWidth={1}>
      <circle cx={size / 2} cy={size / 2} r={size / 2} stroke={'none'} />
      <circle cx={size / 2} cy={size / 2} r={size / 2 - 0.5} fill={'none'} />
    </g>
  </svg>
)

SVG_Dot.defaultProps = {
  fill: 'none',
  size: 26,
  stroke: '#707070'
}

SVG_Dot.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  stroke: PropTypes.string
}
