import { Box } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

export const ContentVeryNarrow = (props) => {
  return (
    <Box sx={{
      maxWidth: 800,
      px: 2,
      mx: 'auto',
      ...props.sx
    }}>
      {props.children}
    </Box>
  )
}

ContentVeryNarrow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  sx: PropTypes.object
}
