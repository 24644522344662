import { Stack, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SVG_Dot } from '../../'

export const SolutionPagination = ({ count, onChange, selected }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  const data = []
  for (let i = 0; i < count; i++) data.push(i)

  return (
    <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
      {data.map((key) => (
        <span
          key={key}
          onClick={() => onChange(key)}
          style={{ cursor: 'pointer' }}
        >
          <SVG_Dot
            fill={selected === key ? primary.main : 'none'}
            stroke={selected === key ? primary.main : '#707070'}
          />
        </span>
      ))}
    </Stack>
  )
}

SolutionPagination.propTypes = {
  count: PropTypes.number,
  onChange: PropTypes.func,
  selected: PropTypes.number
}
