import { useEffect, useState } from 'react'
import { getSession, setSession } from '../api'

const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_API_USER: identifier,
  REACT_APP_API_PASSWORD: password
} = process.env

export const useBearer = () => {
  const [authToken, setAuthToken] = useState(null)
  const [loading, setLoading] = useState(false)

  const requestToken = async () => {
    if (loading === true) return
    try {
      setLoading(true)

      const response = await fetch(`${BASE_URL}/api/auth/local`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ identifier, password })
      })

      if (!response.ok) {
        setLoading(false)
        throw new Error(`useBearer | requestToken | error: ${response.status}`)
      }

      const data = await response.json()
      const token = data.jwt

      setAuthToken(token)
      setSession('token', token)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('useBearer | requestToken | error:', error.message)
      throw error
    }
  }

  const hasValidToken = () => {
    try {
      // token has lifetime of 1 month by default
      const _1MS = 1
      const _1S = 1000 * _1MS
      const _1M = 60 * _1S
      const _1H = 60 * _1M
      const _1D = 24 * _1H

      const token = getSession('token')
      if (!token) return false

      const payload = JSON.parse(atob(token.split('.')[1]))

      const expirationDate = new Date(payload.exp * 1000)
      const currentDate = new Date()

      return expirationDate - currentDate > _1D
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (hasValidToken() === false && loading === false) {
      requestToken()
    } else setAuthToken(getSession('token'))
  }, [loading])

  return authToken
}
