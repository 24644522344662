import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { ContactForm, ContactPerson } from '.'

export const DZ_Contact = ({ data }) => (
  <Grid container>
    <Grid
      xs={12}
      md={6}
      item
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <ContactForm />
    </Grid>
    <Grid
      xs={12}
      md={6}
      item
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <ContactPerson data={data} />
    </Grid>
  </Grid>
)

DZ_Contact.propTypes = {
  data: PropTypes.object
}
