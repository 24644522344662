//
import { Close, Menu } from '@mui/icons-material'
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SVG_BgHeader, SVG_KarmaLogo } from '.'
import { useMainMenu } from '../hooks'

const Logo = ({ size, ...rest }) => (
  <Box {...rest}>
    <Link to={'/'}>
      <SVG_KarmaLogo style={{ width: size, height: size }} />
    </Link>
  </Box>
)

Logo.propTypes = {
  size: PropTypes.number.isRequired
}

const MenuLink = ({ title, url, size, ...rest }) => (
  <Link
    style={{
      color: '#000000',
      fontSize: size,
      textDecoration: 'none'
    }}
    to={url}
    {...rest}
  >
    {title}
  </Link>
)

MenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
}

const HeaderBody = ({ data }) => {
  const theme = useTheme()
  const isGtXL = useMediaQuery(theme.breakpoints.up('xl'))
  const primaryData = data.slice(0, 4)
  const secondaryData = data.slice(4, 8)

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{
        backgroundColor: 'white',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        height: 148,
        maxWidth: 1920,
        position: 'fixed',
        px: { xl: 10, lg: 5, md: 0 },
        top: 0,
        width: 1,
        zIndex: 999999
      }}
    >
      {/* graues design element */}
      <SVG_BgHeader
        style={{
          display: isGtXL ? 'block' : 'none',
          pointerEvents: 'none',
          position: 'absolute',
          right: -150,
          top: -888,
          zIndex: 1
        }}
      />

      <Stack alignItems={'center'} direction={'row'}>
        <Logo size={100} />

        <Stack direction={'row'} spacing={3} sx={{ ml: 3 }}>
          {primaryData.map((item, key) => {
            const { title, url } = item.attributes

            return <MenuLink key={key} title={title} url={url} size={26} />
          })}
        </Stack>
      </Stack>

      <Stack
        alignItems={'top'}
        direction={'row'}
        spacing={isGtXL ? 3 : 2}
        sx={{ mt: 3, mr: { xl: 10, lg: 5, md: 2 }, zIndex: 11 }}
      >
        {secondaryData.map((item, key) => {
          const { title, url } = item.attributes

          return <MenuLink key={key} title={title} url={url} size={20} />
        })}
      </Stack>
    </Stack>
  )
}

HeaderBody.propTypes = {
  data: PropTypes.array.isRequired
}

const HeaderBodyMobile = ({ data }) => {
  const [open, setOpen] = useState(false)

  return (
    <Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: 'white',
          position: 'fixed',
          width: 1,
          zIndex: 999999
        }}
      >
        <Logo size={50} sx={{ pl: 2, pt: 1 }} />

        <IconButton onClick={() => setOpen((prev) => !prev)}>
          {!open ? <Menu /> : <Close />}
        </IconButton>
      </Stack>

      <Collapse
        in={open}
        sx={{
          position: 'absolute',
          left: 0,
          top: 70,
          width: 1,
          zIndex: 9999999
        }}
      >
        <Stack
          sx={{
            pl: 2,
            pt: 2,
            backgroundColor: 'rgba(128, 128, 128, 0.95)',

            width: 1
          }}
          justifyContent={'center'}
          spacing={3}
        >
          {data.map((item, key) => {
            const { title, url } = item.attributes

            return (
              <MenuLink
                key={key}
                title={title}
                url={url}
                size={key <= 3 ? 26 : 20}
                onClick={() => setOpen(false)}
              />
            )
          })}
        </Stack>
      </Collapse>
    </Stack>
  )
}

HeaderBodyMobile.propTypes = {
  data: PropTypes.array.isRequired
}

export const Header = () => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('lg'))
  const { data } = useMainMenu()
  if (!data) return null

  return isGtLG ? (
    <HeaderBody data={data.data} />
  ) : (
    <HeaderBodyMobile data={data.data} />
  )
}
