import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const SVGPic = ({ src, size, ...rest }) => (
  <Box
    component={'img'}
    src={src}
    sx={{ width: size, height: size }}
    {...rest}
  />
)

SVGPic.defaultProps = {
  size: 92
}

SVGPic.propTypes = {
  size: PropTypes.number,
  src: PropTypes.any.isRequired
}
