import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const Scene = ({ children }) => (
  <Box
    sx={{
      backgroundColor: 'none',
      height: '100%',
      margin: 'auto',
      maxHeight: '100%',
      maxWidth: 1920,
      overflow: 'auto',
      width: '100%'
    }}
  >
    {children}
  </Box>
)

Scene.propTypes = {
  children: PropTypes.any.isRequired
}
