import { Box, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SVGPic, Typo20RTF, Typo26Bold } from '../../'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

export const SolutionCarousel = ({ data, mask, selected }) => {
  return (
    <Stack direction={'row'} sx={{ width: 1 }}>
      {data.map((item, key) => {
        const {
          detailedCaption: caption,
          detailedDescription: description,
          image,
          icon
        } = item.attributes

        const { url } = image.data.attributes
        const { url: iconUrl } = icon.data.attributes
        const { url: maskUrl } = mask.data.attributes

        return selected === key ? (
          <Grid container spacing={5} sx={{ mt: 2 }} key={key}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                component={'img'}
                src={`${BASE_URL}${url}`}
                sx={{
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: 1,
                  maskImage: `url(${BASE_URL}${maskUrl})`,
                  WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
                  maskPosition: 'right',
                  maskRepeat: 'no-repeat'
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} sx={{ pt: 10 }}>
              <SVGPic src={`${BASE_URL}${iconUrl}`} size={93} />

              <Typo26Bold sx={{ maxWidth: 600, mb: 3, mx: 2 }}>
                {caption}
              </Typo26Bold>
              <Typo20RTF sx={{ maxWidth: 600, mx: 1 }}>{description}</Typo20RTF>
            </Grid>
          </Grid>
        ) : null
      })}
    </Stack>
  )
}

SolutionCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  mask: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired
}
