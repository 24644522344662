import { Card, CardContent, CardHeader, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import * as React from 'react'
import Box from '@mui/material/Box'
import { SVG_BgOurStrengths, SVGPic, Typo20, Typo26Bold, Typo32, Typo43 } from '..'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const OurStrengthsMobile = ({ data }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  const isLG = useMediaQuery(theme.breakpoints.only('lg'))

  return (
    <Stack
      alignItems={'center'}
      sx={{
        _position: 'relative',
        _height: 966,
        width: 1,
        background: `linear-gradient(to right, ${primary.dark} 0%, ${primary.main} 50%, ${primary.light} 100%)`
      }}
    >
      <Typo26Bold
        color={primary.contrastText}
        sx={{ zIndex: 1, mt: { xl: 10, lg: 20 }, mb: 2.4 }}
      >
        Unsere Stärken
      </Typo26Bold>

      <Stack
        sx={{ width: 1, zIndex: 1, display: 'flex', justifyContent: 'center' }}
      >
        {data.map((item, key) => {
          const theme = useTheme()
          const { contrastText: color } = theme.palette.primary
          const { description, headline, icon } = item.attributes
          const { url } = icon.data.attributes
          const src = `${BASE_URL}${url}`

          return (
            <Card
              elevation={0}
              key={key}
              sx={{
                backgroundColor: 'transparent',
                maxWidth: { lg: 350, xl: 400 },
                flexGrow: 1
              }}
            >
              <CardHeader
                avatar={<SVGPic src={src} size={isLG === true ? 200 : 300} />}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              />
              <CardContent>
                <Typo32 color={color}>{headline}</Typo32>
                <Typo20 color={color}>{description}</Typo20>
              </CardContent>
            </Card>
          )
        })}
      </Stack>
    </Stack>
  )
}

OurStrengthsMobile.propTypes = {
  data: PropTypes.array.isRequired
}

const OurStrengthsDesktop = ({ data }) => {
  const theme = useTheme()
  const { primary } = theme.palette
  const isLG = useMediaQuery(theme.breakpoints.only('lg'))

  return (
    <Stack alignItems={'center'} sx={{ position: 'relative', height: 'auto', width: '100%' }}>
      <SVG_BgOurStrengths />

      <Box sx={{ zIndex: 1, mt: '150px', mb: '60px', alignItems: 'center' }}>
        <Typo43 color={primary.contrastText}>
          Unsere Stärken
        </Typo43>
      </Box>

      <Box sx={{
        zIndex: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1%',
        maxWidth: '1500px', 
        paddingBottom: '40px'
      }}>
        {data.map((item, key) => {
          const theme = useTheme()
          const { contrastText: color } = theme.palette.primary
          const { description, headline, icon } = item.attributes
          const { url } = icon.data.attributes
          const src = `${BASE_URL}${url}`
          const fontSizeHeader = `${(24 / window.screen.width) * 100}vw`
          const fontSize = `${(16 / window.screen.width) * 100}vw`

          return (
            <Card
              elevation={0}
              key={key}
              sx={{ backgroundColor: 'transparent' }}>

              <Box
                component={'img'}
                src={src}
                sx={{ display: 'block', margin: '0 auto 35px', width: '80%', height: 'auto' }}
              />

              <CardContent>
                {isLG === true ? (
                  <Typography style={{ color, fontFamily: 'Titillium Black', fontSizeHeader }}>
                    {headline}
                  </Typography>
                ) : (
                  <Typo32 color={color}>{headline}</Typo32>
                )}

                {isLG === true ? (
                  <Typography style={{ color, fontFamily: 'Titillium Regular', fontSize }}>
                    {description}
                  </Typography>
                ) : (
                  <Typo20 color={color}>{description}</Typo20>
                )}
              </CardContent>
            </Card>
          )
        })}
      </Box>
    </Stack>
  )
}

OurStrengthsDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

export const DZ_OurStrengths = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <OurStrengthsDesktop data={data} />
  ) : (
    <OurStrengthsMobile data={data} />
  )
}

DZ_OurStrengths.propTypes = {
  data: PropTypes.array.isRequired
}
