import { useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'

export const SVG_BgContactBarMobile = ({ width, height }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox={`0 0 ${width} ${height}`}
      version="1.1"
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      xmlSpace={'preserve'}
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2
      }}
    >
      <g transform="matrix(1,0,0,1,-1058.18,-700.488)">
        <path
          d="M1057.2,729.314C1093.3,718.835 1105.06,715.283 1144.91,709.855C1253.31,695.089 1433.86,697.401 1455.05,727.199L1453.81,1025.54L1056.68,1025.85C1056.68,1025.85 1057.2,731.126 1057.2,729.314Z"
          fill={primary.main}
        />
      </g>
    </svg>
  )
}

SVG_BgContactBarMobile.defaultProps = {
  width: 396,
  height: 324
}

SVG_BgContactBarMobile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
