import React from 'react'

export const rtfBoldedText = (txt, normal = 500, bold = 900) =>
  txt.split(/\*\*(.*?)\*\*/g).map((chunk, key) => {
    const isOdd = key % 2 === 1

    return (
      <span key={key} style={{ fontWeight: isOdd ? bold : normal }}>
        {chunk}
      </span>
    )
  })

export const rtfColoredText = (txt, color, bColor) =>
  txt.split(/\*\*(.*?)\*\*/g).map((chunk, key) => {
    const isOdd = key % 2 === 1

    return (
      <span key={key} style={{ color: isOdd ? bColor : color }}>
        {chunk}
      </span>
    )
  })

export const isValidEmail = (email) => {
  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regExp.test(email) ? true : false
}

export const isValidLength = (val, len) => val && val.length >= len

export const isValidPassword = (password) => {
  const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/

  return regExp.test(password) ? true : false
}

export const parseDZData = (data) => {
  const keysToRemove = [
    'createdAt',
    'locale',
    'publishedAt',
    'title',
    'updatedAt'
  ]
  for (const key of keysToRemove) if (data[key]) delete data[key]

  if (Object.keys(data).length !== 1)
    console.error(`key length mismatch. expected 1 key.`)

  return data
}

export const getPageTitle = (data) => {
  const key_name = Object.keys(data)[0]
  const hasTitle = data[key_name].find(
    (f) => f.__component === 'common.text-block'
  )

  return hasTitle ? hasTitle.title : null
}
