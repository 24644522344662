import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import {
  PlekListItem20,
  PlekListItem26,
  SVG_Expand,
  Typo20,
  Typo20Bold,
  Typo32
} from '..'

const JobOffersMobile = ({ data, onChange }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  return data.map((job, key) => {
    const { requirements, role, title } = job.attributes
    const handleClick = () => onChange(key)

    const liItem = '&bull; '
    const roleData = role.split(liItem).filter((chunk) => chunk !== '')

    const requirementsData = requirements
      .split(liItem)
      .filter((chunk) => chunk !== '')

    return (
      <Accordion
        key={key}
        elevation={0}
        onChange={handleClick}
        sx={{ width: 1, expanded:'false'}}
      >
        <AccordionSummary expandIcon={<SVG_Expand />}>
          <Typo20Bold>{title}</Typo20Bold>
        </AccordionSummary>

        <AccordionDetails>
          <Stack alignItems={'center'}>
            <Stack>
              <Typo20Bold sx={{ color: primary.main }}>Deine Rolle</Typo20Bold>

              {roleData.map((role, key) => (
                <PlekListItem20 data={role} key={key} />
              ))}

              <Typo20Bold sx={{ color: primary.main }}>
                Anforderungen
              </Typo20Bold>

              {requirementsData.map((requirement, key) => (
                <PlekListItem20 data={requirement} key={key} />
              ))}
            </Stack>

            <Typo32 sx={{ color: primary.main, mt: 5 }}>
              Bewirb dich jetzt!
            </Typo32>

            <Typo20 sx={{ width: 1 }}>
              Wir freuen uns über ein kurzes und knackiges Anschreiben, deinen
              Lebenslauf und relevante Arbeitszeugnisse. Schicke jetzt deine
              Bewerbung inkl. Gehaltsvorstellung und frühestmöglichem
              Eintrittstermin per Mail an{' '}
              <a
                href={'mailto:vincent.schecker@karma.de'}
                style={{ color: primary.main }}
              >
                Vincent Schlecker - jobs@karma.de
              </a>
            </Typo20>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  })
}

const JobOffersDesktop = ({ data, onChange }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  return data.map((job, key) => {
    const { requirements, role, title } = job.attributes
    const handleClick = () => onChange(key)

    const liItem = '&bull; '
    const roleData = role.split(liItem).filter((chunk) => chunk !== '')

    const requirementsData = requirements
      .split(liItem)
      .filter((chunk) => chunk !== '')

    return (
      <Accordion
        key={key}
        elevation={0}
        onChange={handleClick}
        sx={{ maxWidth: 1200, expanded:'false' }}
      >
        <AccordionSummary expandIcon={<SVG_Expand />}>
          <Typo32>{title}</Typo32>
        </AccordionSummary>

        <AccordionDetails>
          <Stack alignItems={'center'}>
            <Stack direction={'row'} spacing={10}>
              <Stack sx={{ width: 0.5 }}>
                <Typo20Bold sx={{ color: primary.main }}>
                  Deine Rolle
                </Typo20Bold>

                {roleData.map((role, key) => (
                  <PlekListItem26 data={role} key={key} />
                ))}
              </Stack>

              <Stack sx={{ width: 0.5 }}>
                <Typo20Bold sx={{ color: primary.main }}>
                  Anforderungen
                </Typo20Bold>

                {requirementsData.map((requirement, key) => (
                  <PlekListItem26 data={requirement} key={key} />
                ))}
              </Stack>
            </Stack>

            <Typo32 sx={{ color: primary.main, mt: 5 }}>
              Bewirb dich jetzt!
            </Typo32>

            <Typo20 sx={{ width: 820 }}>
              Wir freuen uns über ein kurzes und knackiges Anschreiben, deinen
              Lebenslauf und relevante Arbeitszeugnisse. Schicke jetzt deine
              Bewerbung inkl. Gehaltsvorstellung und frühestmöglichem
              Eintrittstermin per Mail an{' '}
              <a
                href={'mailto:vincent.schecker@karma.de'}
                style={{ color: primary.main }}
              >
                Vincent Schlecker - jobs@karma.de
              </a>
            </Typo20>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  })
}

export const JobOffers = ({ data, selected, onChange }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <JobOffersDesktop data={data} onChange={onChange} selected={selected} />
  ) : (
    <JobOffersMobile data={data} onChange={onChange} selected={selected} />
  )
}

JobOffers.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.number
}
