import React from 'react'
import {
  DZ_GotoPage,
  PageWrapperLandingPage,
  SVG_Plektrum,
  Typo20,
  Typo20Bold,
  Typo26Bold,
  Typo32,
  Typo43,
  Typo50
} from '../../components'
import { Box, List, ListItem, ListItemIcon, useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import { ContentNarrow } from '../../components/wrapper/ContentNarrow'
import { ContentVeryNarrow } from '../../components/wrapper/ContentVeryNarrow'
import { CenteredOverPlektrum } from '../../components/wrapper/CenteredOverPlektrum'
import { OverVioletGradient } from '../../components/wrapper/OverVioletGradient'
import { OverGray } from '../../components/wrapper/OverGray'
import { BigBadge } from '../../components/wrapper/BigBadge'
import { VerticalLine } from '../../components/wrapper/VerticalLine'

export const LPGebaeudeEnergieGesetz = () => {
  const theme = useTheme()
  const { primary } = theme.palette

  return (
    <PageWrapperLandingPage data={{ title: 'Gebäudeenergiegesetz' }}>
      <CenteredOverPlektrum>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            maxWidth: '100%',
            zIndex: 0,
            marginBottom: '80px',
          }}
        >
          <div
            style={{
              display: 'flex',
              order: 0,
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: 'auto',
              alignSelf: 'center',
              backgroundImage: 'url("/landingpages/geg/projects.bg.right.svg")',
              backgroundSize: '1060px',
              backgroundPositionX: '91px',
              backgroundPositionY: 'calc(max(43px, -65vw + 447px))',
              backgroundRepeat: 'no-repeat',
              marginBottom: '-150px',
              paddingBottom: '150px',
              marginTop: '-300px',
              paddingTop: '300px',
              minWidth: '1200px',
              maxWidth: '100%',
              overflow: 'hidden',
              zIndex: 0
            }}
          >
            <div
              style={{
                display: 'block',
                width: '520px',
                maxWidth: '90vw',
                overflow: 'hidden',
                zIndex: 0,
                alignContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '2em'
              }}
            >
              <div style={{
                color: '#000000',
                display: 'inline-block',
                fontFamily: 'Titillium Black',
                fontSize: 36,
                paddingBottom: '10px'
              }}
              >
                Erfüllen Ihre Gebäude die neuen Anforderungen des GEG?<br />
              </div>

              <div style={{
                color: '#000000',
                display: 'inline-block',
                fontFamily: 'Titillium Regular',
                fontSize: 15,
                fontWeight: 'bolder',
                paddingBottom: '25px'
              }}
              >
                Wichtige Änderung im Gebäudeenergiegesetz (GEG):
              </div>

              <div
                style={{
                  display: 'inline-block',
                  fontSize: 15, fontWeight: 'normal',
                  paddingBottom: '10px'
                }}
              >
                <div>
                  Bis zum
                  <span
                    style={{
                      color: '#a503c0',
                      display: 'inline',
                      fontWeight: 'bolder'
                    }}
                  >
                  &nbsp;31.12.2024&nbsp; 
                </span>
                  müssen Nichtwohngebäude
                </div>
                <div>
                <span
                  style={{
                    color: '#a503c0',
                    display: 'inline',
                    fontWeight: 'bolder'
                  }}
                > 
                  mit einer Heizung oder Klimaanlage &gt; 290 kW Nennleistung
                </span>
                </div>

                <div>
                  mit einer digitalen Energieüberwachungstechnik ausgestattet werden.
                </div>

              </div>

              <div style={{
                color: '#000000',
                fontFamily: 'Titillium Regular',
                fontSize: 15,
                display: 'inline-block',
                fontWeight: 'normal'
              }}>
                Ansonsten drohen Sanktionen in Höhe von bis zu <span
                style={{ display: 'inline', color: '#a503c0', fontWeight: 'bolder' }}>50.000€.</span><br />
              </div>
            </div>
          </div>
        </div>
      </CenteredOverPlektrum>

      <OverVioletGradient
        sx={{
          marginBottom: '3em',
        }}
      >
        <ContentVeryNarrow>
          <Stack spacing={5} color={primary.contrastText}
                 style={{
                   alignContent: 'center',
                   marginLeft: 'auto',
                   marginRight: 'auto',
                   marginTop: '-20px',
                   marginBottom: '',
                   paddingTop: '40px'
                 }}
          >
            <Stack direction="row" spacing={3} style={{ marginTop: '60px', }}>
              <Box width={90} sx={{ flexShrink: 0 }}>
                <VerticalLine>
                </VerticalLine>
              </Box>
              <Box>
                <Typo26Bold>
                  So checken Sie, ob Ihre Gewerbe&shy;immobilien betroffen sind
                </Typo26Bold>
              </Box>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Box>
                <BigBadge>
                  <Typo50>
                    1
                  </Typo50>
                </BigBadge>
              </Box>
              <Box>
                <Typo20>
                  Überprüfen Sie jeweils die Nennleistung der Heizungs- bzw.
                  Klimaanlagen in all Ihren Liegenschaften. Sie finden diese Angaben z. B.
                  im Gerätehandbuch oder auf dem Typenschild am Gerät.
                </Typo20>
              </Box>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Box>
                <BigBadge>
                  <Typo50>
                    2
                  </Typo50>
                </BigBadge>
              </Box>
              <Box>
                <Typo20>
                  Wenn die Nennleistung von <span style={{ fontWeight: 'bolder' }}>mindestens</span> einer Anlage in
                  einem Gebäude
                  <span style={{ fontWeight: 'bolder' }}> &gt; 290 kW</span> beträgt, besteht dringender
                  Handlungsbedarf, denn bis zum
                  <span style={{ fontWeight: 'bolder' }}> 31.12.2024</span> müssen Sie ein System zum Monitoring der
                  Energieverbräuche installiert
                  haben.
                </Typo20>
              </Box>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Box>
                <BigBadge>
                  <div style={{
                    display: 'inline-block',
                    width: '35%',
                    height: '65%',
                    borderColor: primary.contrastText,
                    borderBottom: '9px solid',
                    borderRight: '9px solid',
                    marginTop: '10%',
                    transform: 'rotate(45deg)'
                  }}>
                  </div>
                </BigBadge>
              </Box>
              <Box>
                <Typo32>
                  KARMA erfüllt alle An&shy;for&shy;de&shy;rungen –<br />
                  Nehmen Sie Kontakt auf
                </Typo32>
              </Box>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Box width={90} sx={{
                flexShrink: 0,
              }}>
                <VerticalLine>
                </VerticalLine>
              </Box>
              <div>
                {/* Insert button */}
                <DZ_GotoPage
                  href={'/kontakt'}
                  sx={{
                    borderColor: primary.contrastText,
                    color: primary.contrastText,
                    marginTop: '-15px',
                    marginBottom: '60px',
                    zIndex: 1,
                    '&:hover': {
                      color: primary.main,
                      borderColor: primary.contrastText,
                      backgroundColor: primary.contrastText
                    }
                  }}
                  title={'Kontakt'}
                />
              </div>
            </Stack>
          </Stack>
        </ContentVeryNarrow>
      </OverVioletGradient>

      <ContentNarrow>
        <Typo43 sx={{ textAlign: 'center' }}>
          Diese Anforderungen gelten für Ihre
        </Typo43>
        <Typo43 sx={{ textAlign: 'center' }}>Energieüber&shy;wachungs&shy;technik</Typo43>
        <List
          sx={{
            columns: { xs: 1, md: 2 },
            breakInside: 'avoid-column',
            lineHeight: 0.5,
            marginBottom: '50px'
          }}
        >

          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Box>

              <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                Kontinuierliche Überwachung, Protokollierung und Analyse
              </Typo20Bold>

              <Typo20 sx={{ display: 'inline' }}>
                der Verbräuche aller Hauptenergieträger sowie aller gebäudetechnischen Systeme
              </Typo20>

            </Box>

          </ListItem>

          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Box>

              <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                Definition von Anforderungswerten
              </Typo20Bold>

              <Typo20 sx={{ display: 'inline' }}>
                hinsichtlich der Energieeffizienz des Gebäudes
              </Typo20>

            </Box>

          </ListItem>

          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Box>

              <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                Benennung einer Kontaktperson
              </Typo20Bold>

              <Typo20 sx={{ display: 'inline' }}>
                für das Gebäude-Energiemanagement um Informationen zu Verbesserungspotenzialen zu bündeln
              </Typo20>

            </Box>

          </ListItem>

          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Box>

              <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                Datenerhebung und -zugang
              </Typo20Bold>

              <Typo20 sx={{ display: 'inline' }}>
                über eine gängige und frei konfigurierbare Schnittstelle für eine firmen- und herstellerunabhängige
                Auswertung
              </Typo20>

            </Box>

          </ListItem>

          <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Box>

              <Typo20Bold sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                Benachrichtigung bei Effizienzverlusten
              </Typo20Bold>

              <Typo20 sx={{ display: 'inline' }}>
                von gebäudetechnischen Systemen
              </Typo20>
            </Box>

          </ListItem>
        </List>
      </ContentNarrow>

      <OverGray>
        <ContentNarrow>
          <Typo43 sx={{ textAlign: 'center', fontSize: '49px', paddingBottom: '1em' }}>
            Erfüllen Sie die Anforde&shy;rungen sicher<br /> und effizienter – mit KARMA
          </Typo43>

          <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent={'space-around'}
                 sx={{ gap: '3%' }}>
            <Box>
              <img src="/landingpages/geg/anforderungen.webp" alt="Anforderungen"
                   style={{
                     maxHeight: 240,
                     maxWidth: '100%'
                   }}
              />
            </Box>
            <Box>
              <Box style={{ paddingLeft: '4%' }}><Typo26Bold>KARMA</Typo26Bold></Box>
              <List>
                <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <SVG_Plektrum />
                  </ListItemIcon>

                  <Box>
                    <Typo20>
                      erfüllt alle Anforderungen
                    </Typo20>
                  </Box>
                </ListItem>
                <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <SVG_Plektrum />
                  </ListItemIcon>

                  <Box>
                    <Typo20>
                      bestätigt dem Kunden schriftlich die
                      gesetzeskonforme Erfüllung
                    </Typo20>
                  </Box>
                </ListItem>
                <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <SVG_Plektrum />
                  </ListItemIcon>

                  <Box>
                    <Typo20>
                      optimiert den Betrieb der Anlagen
                    </Typo20>
                  </Box>
                </ListItem>

                <ListItem sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <SVG_Plektrum />
                  </ListItemIcon>

                  <Box>
                    <Typo20>
                      kann zu Energieeinsparungen beitragen und Energiekosten reduzieren
                    </Typo20>
                  </Box>
                </ListItem>
              </List>
            </Box>
          </Stack>

          <Stack>
            <Box sx={{ alignItems: 'baseline', breakInside: 'avoid' }}>

              <Box sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '1%',
                width: '600px',
                maxWidth: '100%',
                textAlign: 'center'
              }}>

                <Typo26Bold sx={{ textAlign: 'center' }}>
                  Nehmen Sie Kontakt zu uns auf und erhalten
                  Sie unverbindlich ein Angebot inklusive
                  Prüfung der Fördermöglichkeiten.
                </Typo26Bold>

                <Box sx={{
                  display: 'inline-block',
                  textAlign: 'left',
                  marginTop: '0.7em'
                }}>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center', marginBottom: '0.5em' }}>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <img src="/landingpages/geg/icon_email.svg" alt="Anforderungen" />
                    </ListItemIcon>
                    <Typo32 sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                      info@karma.de
                    </Typo32>
                  </Stack>

                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <img src="/landingpages/geg/icon_phone.svg" alt="Anforderungen" />
                    </ListItemIcon>
                    <Typo32 sx={{ color: primary.main, pr: .3, display: 'inline' }}>
                      +49 7141 25878-111
                    </Typo32>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </ContentNarrow>
      </OverGray>
    </PageWrapperLandingPage>
  )
}
