// modules
import { CssBaseline, Paper } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { createBrowserHistory } from 'history'
import React, { useEffect, useRef } from 'react'
import { Route, Router, Switch, useLocation } from 'react-router-dom'
// scripts
import { getSession, setSession } from './api'
import { Scene, Viewport } from './components'
import {
  AboutUsProvider,
  AGBProvider,
  AssemblyPartnerProvider,
  CareerProvider,
  ConsultingProvider,
  ContactProvider,
  EKBProvider,
  HomeDataProvider,
  ImpressumProvider,
  MainMenuProvider,
  PrincipleProvider,
  PrivacyProvider,
  ProjectsProvider,
  SocialNetworkProvider,
  SolutionsProvider,
  SubMenuProvider,
  ThemeDataProvider,
  useThemeData
} from './hooks'
import { routes } from './routes'
import { theme } from './theme'
import './App.css'
import { useBearer } from './hooks'
import { Redirect } from 'react-router-dom/cjs/react-router-dom'

export const App = () => {
  const bearer = useBearer()

  const history = createBrowserHistory()

  useEffect(() => {
  }, [
    window.innerWidth,
    window.innerHeight,
    window.screen.width,
    window.screen.height
  ])

  useEffect(() => {
  }, [])

  const topRef = useRef(null)

  if (!getSession('animation')) setSession('animation', false)

  const ScrollTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
      // Safari
      document.body.scrollTop = 0

      // Chrome, Firefox, IE, Opera
      document.documentElement.scrollTop = 0

      const { current } = topRef

      if (current)
        current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [pathname])

    return null
  }

  const MainFrame = () => {
    const themeData = useThemeData()

    // override default theme settings from repo with theme settings from strapi
    useEffect(() => {
      if (!theme || !theme.palette) return
      if (!themeData || !themeData.data) return

      // override primary palette
      theme.palette.primary = themeData.data.primary

      // override secondary palette
      theme.palette.secondary = themeData.data.secondary

      // override grayscale palette
      theme.palette.grayscale = themeData.data.grayscale

      // override breakpoints
      theme.breakpoints.values = themeData.data.breakpoints

      // override spacing
      //theme['spacing'] = themeData.data.spacing
    }, [theme, themeData])

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Viewport>
          <Scene>
            <ScrollTop />
            <Paper elevation={0} ref={topRef}>
              <Switch>
                <Route exact path={'/'}>
                  <Redirect to={'/home'} />
                </Route>
                {routes.map((route, key) => {
                  const { component, path } = route

                  return (
                    <Route component={component} key={key} path={path} strict />
                  )
                })}
              </Switch>
            </Paper>
          </Scene>
        </Viewport>
      </ThemeProvider>
    )
  }

  return bearer === null ? (
    <>loading...</>
  ) : (
    <Router history={history}>
      <ThemeDataProvider>
        <MainMenuProvider>
          <SubMenuProvider>
            <SocialNetworkProvider>
              <HomeDataProvider>
                <SolutionsProvider>
                  <PrincipleProvider>
                    <ConsultingProvider>
                      <ProjectsProvider>
                        <AboutUsProvider>
                          <CareerProvider>
                            <AssemblyPartnerProvider>
                              <ContactProvider>
                                <ImpressumProvider>
                                  <PrivacyProvider>
                                    <AGBProvider>
                                      <EKBProvider>
                                        <MainFrame />
                                      </EKBProvider>
                                    </AGBProvider>
                                  </PrivacyProvider>
                                </ImpressumProvider>
                              </ContactProvider>
                            </AssemblyPartnerProvider>
                          </CareerProvider>
                        </AboutUsProvider>
                      </ProjectsProvider>
                    </ConsultingProvider>
                  </PrincipleProvider>
                </SolutionsProvider>
              </HomeDataProvider>
            </SocialNetworkProvider>
          </SubMenuProvider>
        </MainMenuProvider>
      </ThemeDataProvider>
    </Router>
  )
}
