import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

export const SVG_BgOurStrengths = ({ width, height }) => {
  const theme = useTheme()
  const { primary } = theme.palette
  const [id] = useState(_uniqueId('bgourstrengths'))

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      //height={height}
      height='100%'
      viewBox={`0 0 ${width} ${height}`}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        width: '100%'
      }}
    >
      <defs>
        <linearGradient
          id={'linear-gradient'}
          x1={1}
          x2={0.152}
          y2={1}
          gradientUnits={'objectBoundingBox'}
        >
          <stop offset={0} stopColor={primary.light} />
          <stop offset={0.5} stopColor={primary.main} />
          <stop offset={1} stopColor={primary.dark} />
        </linearGradient>

        <clipPath id={`${id}-clip-path`}>
          <rect
            width={width}
            height={height}
            stroke={'rgba(0,0,0,0)'}
            strokeMiterlimit={10}
            strokeWidth={1}
            fill={'url(#linear-gradient)'}
          />
        </clipPath>
      </defs>
      <g clipPath={'url(#`${id}-clip-path`)'}>
        <g transform={'matrix(0.559, 0.829, -0.829, 0.559, 1278.817,-663.152)'}>
          <path
            d={
              'M1065,12.117c-111.239-.871-190.649,42.29-274.2,117.658C669.361,239.353,560.9,399.494,472.25,535.159c-142.927,218.747-309.038,509.5-393.816,763.6-41.686,124.99-60.365,233.025-59.775,326.052l.079,6.919c6.468,383.573,344.311,508.521,743.727,514.181l11.207.127c112.84.958,230.508-7.436,346.7-22.1,257.845-32.513,570.5-102.082,803.277-232.33,224.366-125.521,368.538-303.365,366.318-554.215l-.041-3.519c-.694-48.965-6.949-100.7-19.244-155.38C2208.235,901.04,2008.2,629.4,1819.613,439.907,1698.088,317.832,1569.6,213.1,1420.96,128.4,1315.876,68.529,1214.354,21.353,1091.693,13.125q-12.015-.806-23.56-.972Z'
            }
            fill={'url(#linear-gradient)'}
            stroke={'rgba(0,0,0,0)'}
            strokeMiterlimit={10}
            strokeWidth={1}
          />
        </g>
      </g>
    </svg>
  )
}

SVG_BgOurStrengths.defaultProps = {
  width: 1920,
  height: '100%'
}

SVG_BgOurStrengths.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
