import PropTypes from 'prop-types'
import React from 'react'
import { Typo26Bold, Typo43 } from '..'
import { useMediaQuery, useTheme } from '@mui/material'

const SectionHeaderDesktop = ({ title }) => (
  <Typo43 sx={{ mt: 10, mb: 5, textAlign: 'center' }}>{title}</Typo43>
)

SectionHeaderDesktop.propTypes = {
  title: PropTypes.string.isRequired
}

const SectionHeaderMobile = ({ title }) => (
  <Typo26Bold sx={{ mt: 10, mb: 5, textAlign: 'center' }}>{title}</Typo26Bold>
)

SectionHeaderMobile.propTypes = {
  title: PropTypes.string.isRequired
}

export const DZ_SectionHeader = ({ title }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <SectionHeaderDesktop title={title} />
  ) : (
    <SectionHeaderMobile title={title} />
  )
}

DZ_SectionHeader.propTypes = {
  title: PropTypes.string.isRequired
}
