import { Box, Collapse, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { ImageItem } from './BigPic/ImageItem'
import { MaskItem } from './BigPic/MaskItem'
import { rtfColoredText } from '../../api'

import BPM_L from '../../img/mobile/BigPic.Mobile.L.svg'
import BPM_M from '../../img/mobile/BigPic.Mobile.M.svg'
import BPM_S from '../../img/mobile/BigPic.Mobile.S.svg'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export const BigPic = ({ data, ...rest }) => {
  if (!data) return null

  const { pathname } = useLocation()

  const theme = useTheme()
  const isGtSM = useMediaQuery(theme.breakpoints.up('sm'))

  const { REACT_APP_BASE_URL: BASE_URL } = process.env
  const { description, headline, image, left, mask, maskMode } = data

  const { attributes: imageAttributes } = image.data
  const imageUrl = `${BASE_URL}${imageAttributes.url}`

  const { attributes: maskAttributes } = mask.data
  const maskUrl = isGtSM ? `${BASE_URL}${maskAttributes.url}` : BPM_L

  const isTextOnRightSide = left > 1000

  const mobileMatrixData = [
    { pathname: '/', height: 700, left: -390, width: '200%', maskUrl: BPM_L },
    { pathname: '/home', height: 700, left: -390, width: '200%', maskUrl: BPM_L },
    {
      pathname: '/geg',
      height: isGtSM? 500: 580,
      width: '200%',
      maskUrl: BPM_S
    },
    {
      pathname: '/loesungen',
      height: 500,
      left: 0,
      width: '100%',
      maskUrl: BPM_L
    },
    {
      pathname: '/das-prinzip',
      height: 407,
      left: 0,
      width: '100%',
      maskUrl: BPM_M
    },
    {
      pathname: '/consulting',
      height: 407,
      left: 0,
      width: '175%',
      maskUrl: BPM_M
    },
    {
      pathname: '/projekte',
      height: 350,
      left: 0,
      width: '130%'
    },
    {
      pathname: '/projekte/harpen-immobilien',
      height: 407,
      left: 0,
      width: '130%',
      maskUrl: BPM_M
    },
    {
      pathname: '/ueber-uns',
      height: 500,
      left: 0,
      width: '175%',
      maskUrl: BPM_L
    },
    {
      pathname: '/karriere',
      height: 370,
      left: 0,
      width: '140%',
      maskUrl: BPM_M
    },
    {
      pathname: '/montagepartner',
      height: 370,
      left: 0,
      width: '140%',
      maskUrl: BPM_M
    },
    {
      pathname: '/kontakt',
      height: 470,
      left: 0,
      width: '200%',
      maskUrl: BPM_S
    },
    {
      pathname: '/impressum',
      height: 470,
      left: 0,
      width: '200%',
      maskUrl: BPM_S
    },
    {
      pathname: '/datenschutz',
      height: 470,
      left: 0,
      width: '200%',
      maskUrl: BPM_S
    },
    { pathname: '/agb', height: 480, left: 0, width: '200%', maskUrl: BPM_S },
    { pathname: '/ekb', height: 480, left: 0, width: '200%', maskUrl: BPM_S }
  ]

  let mmd = mobileMatrixData.find((f) => f.pathname === pathname)

  // polyfill (needed for sub pages as pathname are different)
  if (mmd === undefined)
    mmd = {
      pathname,
      height: 600,
      left: 0,
      width: '175%',
      maskUrl: BPM_L
    }

  return isGtSM ? (
    <Box sx={{ position: 'relative', pt: 15, justifyContent: 'center', zIndex: 1 }} {...rest}>
      {/* desktop */}
      {/* maskMode 'layer' = 2 layers; big pic in the background, mask on top */}
      <Collapse in={maskMode === 'layer'}>
        <ImageItem src={imageUrl} />
        <MaskItem src={maskUrl} />
      </Collapse>

      {/* maskMode 'mask' = 1 layer; big pic using css imageMask attribute defined through mask image */}
      <Collapse in={maskMode === 'mask'}>
        <ImageItem
          src={imageUrl}
          sx={{
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: 700,
            height: 420,
            maskImage: `url(${BASE_URL}${maskUrl})`,
            WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
            maskSize: 450,
            maskRepeat: 'no-repeat',
            maskPosition: 'right'
          }}
        />
        <MaskItem src={maskUrl} />
      </Collapse>

      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 1,
          height: 1,
          maxHeight: 866,
          justifyContent: 'center',
          alignItems: 'right',
          paddingLeft: 2,
          paddingRight: 3,
          margin: 'auto'
        }}
      >
        <Typography
          sx={{
            mt: 16,
            ml: isTextOnRightSide ? 'auto' : { xl: 10, lg: 10, md: 10, sm: 5 },
            fontFamily: headline.fontFamily,
            fontSize: { xl: 56, lg: 42, md: 32, sm: 26 },
            maxWidth: '35%',
            textAlign: 'left'
          }}
          {...rest}
        >
          {rtfColoredText(headline.text, headline.color, headline.bColor)}
        </Typography>

        <Typography
          sx={{
            ml: isTextOnRightSide ? 'auto' : { xl: 10, lg: 10, md: 10, sm: 5 },
            color: description.color,
            fontFamily: description.fontFamily,
            fontSize: { xl: 20, lg: 16, md: 16, sm: 10 },
            maxWidth: '35%'
          }}
          {...rest}
        >
          {description.text}
        </Typography>
      </Stack>
    </Box>
  ) : (
    <Box sx={{ position: 'relative', pt: 15, height: mmd.height, zIndex: 1 }} {...rest}>
      {/* mobile */}
      {/* maskMode 'layer' = 2 layers; big pic in the background, mask on top */}
      <Collapse in={maskMode === 'layer'} sx={{ mt: 7 }}>
        <Box
          component={'img'}
          src={imageUrl}
          sx={{
            objectFit: 'cover',
            overflow: 'hidden',
            width: mmd.width,
            position: 'absolute',
            top: pathname.includes('/geg')? 148: 70,
            height: 160,
            left: mmd.left
          }}
        />
      </Collapse>

      {/* maskMode 'mask' = 1 layer; big pic using css imageMask attribute defined through mask image */}
      <Collapse in={maskMode === 'mask'}>
        <ImageItem
          src={imageUrl}
          sx={{
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: 700,
            height: 420,
            maskImage: `url(${BASE_URL}${maskUrl})`,
            WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
            maskSize: 450,
            maskRepeat: 'no-repeat',
            maskPosition: 'right'
          }}
        />
        <Box
          component={'img'}
          src={maskUrl}
          sx={{ left: 0, objectFit: 'contain', position: 'absolute', width: 1 }}
        />
      </Collapse>

      <Stack sx={{
        position: 'absolute',
        px: 2,
        top:  pathname.includes('/geg')? 306: 229,
        width: 1,
        backgroundColor: mmd.maskUrl ? 'none' : theme.palette.secondary.dark,
        backgroundImage: mmd.maskUrl ? `url(${mmd.maskUrl})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom',
        paddingTop: '15px',
        paddingBottom: '135px'
      }}>
        <Typography
          sx={{
            fontFamily: headline.fontFamily,
            fontSize: 32,
            width: 1,
            mb: 1
          }}
          {...rest}
        >
          {rtfColoredText(headline.text, headline.color, headline.bColor)}
        </Typography>

        <Typography
          style={{
            color: description.color,
            fontFamily: description.fontFamily,
            fontSize: description.fontSize
          }}
          {...rest}
        >
          {description.text}
        </Typography>
      </Stack>
    </Box>
  )
}

BigPic.propTypes = {
  data: PropTypes.object
}
