import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const Viewport = ({ children }) => (
  <Box
    sx={{
      position: 'relative',
      left: '0px',
      top: '0px',
      backgroundColor: 'white',
      height: '100vh',
      overflow: 'hidden',
      width: '100vw'
    }}
  >
    {children}
  </Box>
)

Viewport.propTypes = {
  children: PropTypes.any.isRequired
}
