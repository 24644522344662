import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

export const SVG_BgContactBar = ({ width, height, ...rest }) => {
  const theme = useTheme()
  const { primary } = theme.palette
  const [id] = useState(_uniqueId('bgcontactbar'))

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      //width={width}
      //height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
    >
      <defs>
        <clipPath id={`${id}-clip-path`}>
          <rect width={width} height={height} fill={'#000000'} />
        </clipPath>
      </defs>

      <g clipPath={'url(#`${id}-clip-path`)'}>
        <g transform={'matrix(0.559, 0.829, -0.829, 0.559, 1369.817, -671.57)'}>
          <path
            d="M1047.032,0C937.665.392,859.1,44.121,776.1,119.858,655.454,229.972,547,390.118,458.3,525.75c-143,218.695-309.618,509.107-395.846,762.23C20.056,1412.489.471,1519.911,0,1612.224v6.865c2.028,380.578,332.806,500.76,725.471,501.867h11.018c110.941-.324,226.733-9.982,341.146-25.842,253.9-35.177,562.1-107.746,792.454-239.631,222.028-127.1,365.794-305.216,366.443-554.131v-3.492c-.13-48.584-5.7-99.859-17.167-153.979-58.27-274.638-251.884-541.951-435.179-727.87-118.113-119.773-243.268-222.255-388.462-304.636C1293.074,53.149,1193.784,7.478,1073.27.7q-11.8-.664-23.155-.7Z"
            fill={primary.main}
          />
        </g>
      </g>
    </svg>
  )
}

SVG_BgContactBar.defaultProps = {
  width: 1920,
  height: 756
}

SVG_BgContactBar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
