import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const Typo26Bold = ({ children, family, size, ...rest }) => (
  <Typography style={{ fontFamily: family, fontSize: size }} {...rest}>
    {children}
  </Typography>
)

Typo26Bold.defaultProps = {
  family: 'Titillium Bold',
  size: 26
}

Typo26Bold.propTypes = {
  children: PropTypes.any.isRequired,
  family: PropTypes.string,
  size: PropTypes.number
}
