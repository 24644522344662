import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { rtfBoldedText } from '../../api'

export const Typo20RTF = ({
  children,
  family,
  size,
  whiteSpace,
  wordWrap,
  ...rest
}) => (
  <Typography
    style={{ fontFamily: family, fontSize: size, whiteSpace, wordWrap }}
    {...rest}
  >
    {typeof children === 'string' ? rtfBoldedText(children) : children}
  </Typography>
)

Typo20RTF.defaultProps = {
  family: 'Titillium Regular',
  size: 20,
  whiteSpace: 'pre-line',
  wordWrap: 'break-word'
}

Typo20RTF.propTypes = {
  children: PropTypes.any,
  family: PropTypes.string,
  size: PropTypes.number,
  whiteSpace: PropTypes.string,
  wordWrap: PropTypes.string
}
