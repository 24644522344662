import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'

export const ProjectPicMobile = ({ src, mask, bgImg }) => (
  <Stack sx={{ position: 'relative', minHeight: 400 }}>
    {/* background SVG (usually a pleak in karma gradient color) */}
    <Box
      component={'img'}
      draggable={false}
      src={bgImg}
      sx={{ 
        justifyContent: 'center',
        width: 370, 
        height: 340
     }}
    />

    {/* masked pic */}
    <Box
      component={'img'}
      draggable={false}
      src={src}
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        left: 10,
        top: -5,
        width: 380,
        height: 340,
        maskImage: `url(${mask})`,
        WebkitMaskImage: `url(${mask})`,
        maskSize: 400,
        objectFit: 'cover',
        maskRepeat: 'no-repeat',
        maskPosition: 'center'
      }}
    />
  </Stack>
)

ProjectPicMobile.propTypes = {
  src: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  bgImg: PropTypes.string.isRequired
}

export const ProjectPic = ({ src, mask, bgImg }) => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Stack sx={{ position: 'relative' }}>
      {/* background SVG (usually a plek in karma gradient color) */}
      <Box
        component={'img'}
        draggable={false}
        src={bgImg}
        sx={{
          position: 'absolute',
          left: 25,
          top: 50,
          width: isGtLG ? 760 : 570,
          height: isGtLG ? 680 : 510
        }}
      />

      {/* masked pic */}
      <Box
        component={'img'}
        draggable={false}
        src={src}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          left: 0,
          top: 0,
          width: isGtLG ? 760 : 570,
          height: isGtLG ? 680 : 510,
          maskImage: `url(${mask})`,
          WebkitMaskImage: `url(${mask})`,
          maskSize: isGtLG ? 800 : 600,
          objectFit: 'cover',
          maskRepeat: 'no-repeat',
          maskPosition: 'center'
        }}
      />
    </Stack>
  )
}

ProjectPic.propTypes = {
  src: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  bgImg: PropTypes.string.isRequired
}
