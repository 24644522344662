import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const DZ_GotoPage = ({ href, title, ...rest }) => {
  const history = useHistory()
  const handleClick = () => history.push(href)

  return (
    <Button onClick={handleClick} {...rest}>
      {title}
    </Button>
  )
}

DZ_GotoPage.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
