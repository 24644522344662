import { Stack, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo20RTF, Typo32 } from '../../'

const TheAchievementMobile = ({ data }) => (
  <Stack sx={{ px: 2, mb: 5 }}>
    {data.map((item, key) => {
      return (
        <Typo20RTF key={key} sx={{ mb: 2 }}>
          {item.text}
        </Typo20RTF>
      )
    })}
  </Stack>
)

TheAchievementMobile.propTypes = {
  data: PropTypes.array.isRequired
}

const TheAchievementDesktop = ({ data }) => (
  <Stack direction={'row'} spacing={10} sx={{ maxWidth: 1300, mb: 5 }}>
    {data.map((item, key) => {
      return (
        <Typo20RTF key={key} sx={{ maxWidth: 760, mb: 2 }}>
          {item.text}
        </Typo20RTF>
      )
    })}
  </Stack>
)

TheAchievementDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

export const TheAchievement = ({ data }) => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Stack
      alignItems={'center'}
      sx={{ bgcolor: '#6E23BA', color: 'white', width: 1 }}
    >
      <Typo32 sx={{ my: 5 }}>Der Erfolg</Typo32>

      {isGtLG ? (
        <TheAchievementDesktop data={data} />
      ) : (
        <TheAchievementMobile data={data} />
      )}
    </Stack>
  )
}

TheAchievement.propTypes = {
  data: PropTypes.array.isRequired
}
