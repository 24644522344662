import {Box, useTheme} from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";

export const OverGray = (props) => {
  const theme = useTheme()
  const {grayscale} = theme.palette

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: grayscale[100],
        py: 8,
        ...props.sx
      }}
    >
      {props.children}
    </Box>
  )
}

OverGray.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  sx: PropTypes.object
}
