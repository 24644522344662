import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'

export const MaskItem = ({ src }) => (
  <Box
    component={'img'}
    src={src}
    sx={{ left: 0, objectFit: 'contain', position: 'absolute', width: 1 }}
  />
)

MaskItem.propTypes = {
  src: PropTypes.string.isRequired
}
