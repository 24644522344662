import React from 'react'
import PropTypes from 'prop-types'

const lineWidth = '5px' 
const overflowLineDist = '50px'
const badgeHeight = '90px'

export const BigBadge = (props) => {
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}>
      <div style={{
        position: 'absolute',
        left: '50%',
        width: lineWidth,
        top: `-${overflowLineDist}`,
        height: `calc(100% / 2 - ${badgeHeight} / 2 + ${overflowLineDist})`,
        background: '#fff',
        transform: 'translateX(-50%)',
      }}>
      </div>
      <div style={{
        position: 'absolute',
        left: '50%',
        width: lineWidth,
        bottom: `-${overflowLineDist}`,
        height: `calc(100% / 2 - ${badgeHeight} / 2 + ${overflowLineDist})`,
        background: '#fff',
        transform: 'translateX(-50%)',
      }}>
      </div>
      <div style={{
        width: badgeHeight,
        height: badgeHeight,
        borderRadius: '100%',
        border: '5px solid #fff',
        textAlign: 'center',
      }}>
        {props.children}
      </div>
    </div>
  )
}

BigBadge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  sx: PropTypes.object
}
