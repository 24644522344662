import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

export const SVG_BgProjects = ({ width, height }) => {
  const theme = useTheme()
  const { primary, secondary } = theme.palette
  const [id] = useState(_uniqueId('bgprojects'))

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ position: 'absolute', top: 0, zIndex: 10001 }}
    >
      <defs>
        <clipPath id={`${id}-clip-path`}>
          <rect width={width} height={height} fill={'#fff'} />
        </clipPath>
        <linearGradient
          id={'linear-gradient'}
          x1={0.445}
          y1={-0.176}
          x2={0.394}
          y2={0.286}
          gradientUnits={'objectBoundingBox'}
        >
          <stop offset={0} stopColor={primary.dark} />
          <stop offset={1} stopColor={secondary.light} />
        </linearGradient>
      </defs>
      <g clipPath={'url(#`${id}-clip-path`)'}>
        <path
          d="M-1634.139,2945.457v-617.2c-99.257-220.274-132.131-486.242-129.966-707.35,1.709-172.243,21.21-337.36,70.27-502.919,34.695-117.034,75.966-222.656,161.287-316.214q8.358-9.164,16.831-17.546l2.308-2.27c82.167-80.244,172.655-106.955,289.636-114.136,170.057-10.422,367.216,23.753,531.829,55.024C-426.525,773.277-91.5,857.387,156.334,974.13c121.9,57.435,214.342,119.5,281.534,184.886l4.971,4.888c266.852,265.323,121.541,584.347-156.977,868.573v912.98Z"
          transform={'translate(1636.139 -643.995)'}
          stroke={'rgba(0,0,0,0)'}
          strokeMiterlimit={10}
          strokeWidth={1}
          fill={'url(#linear-gradient)'}
        />
      </g>
    </svg>
  )
}

SVG_BgProjects.defaultProps = {
  width: 1920,
  height: 1212
}

SVG_BgProjects.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
