import { Box, Collapse, Grid, Stack, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo20, Typo32, Typo43 } from '../../'
import { useTheme } from '@emotion/react'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

export const AtAGlance = ({ data }) => {
  const { description, headline, image } = data
  const { url } = image.data.attributes
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Collapse in={true}>
      <Grid container sx={{ mt: 10 }}>
        {/* pic, left side */}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: { xl: 'right', md: 'center' },
            pr: { xl: 5, md: 0 }
          }}
        >
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${url}`}
            sx={{ width: 1 }}
          />
        </Grid>

        {/* desc, right side */}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: { xl: 'left', md: 'center' },
            pl: { xl: 5, md: 0 }
          }}
        >
          <Stack sx={{ alignItems: 'middle', maxWidth: 660, p: { xs: 2 } }}>
            {isGtMD ? (
              <Typo43 sx={{ mb: 3 }}>{headline}</Typo43>
            ) : (
              <Typo32 sx={{ mb: 3 }}>{headline}</Typo32>
            )}
            <Typo20 sx={{ mb: 3 }}>{description}</Typo20>
          </Stack>
        </Grid>
      </Grid>
    </Collapse>
  )
}

AtAGlance.propTypes = {
  data: PropTypes.object.isRequired
}
