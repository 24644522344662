import { Box } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

export const CenteredOverPlektrum = (props) => {
  return (
    <Box sx={{
      textAlign: 'center',
      border: '0px dashed #fc3',
      ...props.sx
    }}>
      <code></code>
      {props.children}
    </Box>
  )
}

CenteredOverPlektrum.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  sx: PropTypes.object
}
