import {
  Box,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typo20, Typo26, Typo43, Typo26Bold } from '../../'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

export const Career = ({data}) => {
  const theme = useTheme()
  const isGreaterThanMd = useMediaQuery(theme.breakpoints.up('md'))

  const history = useHistory()

  const {
    background,
    description,
    headline,
    href,
    mask,
    more,
    picture,
    title
  } = data

  const { url: bgUrl } = background.data.attributes
  const { url } = picture.data.attributes
  const { url: maskUrl } = mask.data.attributes
  
  return (
    <Grid container sx={{ my: 10, px: '0 !important' }}>
      <Grid item xs={12} md={6}>
        <Box
          style={{
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          sx={{
            width: { xs: '80%', sm: '50%', md: '80%'},
          }}
        >
          <img src={`${BASE_URL}${bgUrl}`}
               style={{
                 width: '129%',
                 marginTop: '-10%',
                 marginLeft: '-13%',
                 marginBottom: '-18%'
               }}
          />
          <div
            style={{
              position: 'absolute',
              inset: 0,
            }}
          >
            <img
              draggable={false}
              src={`${BASE_URL}${url}`}
              style={{
                backgroundSize: 'contain',
                backgroundPosition: 'left',
                backgroundRepeat: 'no-repeat',
                maskImage: `url(${BASE_URL}${maskUrl})`,
                WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
                maskRepeat: 'no-repeat',
                maskSize: '74%',
                left: 0,
                top: 28,
                width: '127%',
                position: 'absolute'
              }}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          alignItems={isGreaterThanMd? 'start': 'center'}
          justifyContent={'center'}
          sx={{width: 1, p: {md: 5}}}
        >
          {
            isGreaterThanMd
              ? (
                <div>
                  <Typo26>{title}</Typo26>
                  <Typo43>{headline}</Typo43>
                  <Typo20>{description}</Typo20>
                </div>
              ) : (
                <div style={{textAlign: 'center'}}>
                  <Typo20>{title}</Typo20>
                  <Typo26Bold>{headline}</Typo26Bold>
                  <Typo20>{description}</Typo20>
                </div>
              )
          }

          <Button onClick={() => history.push(href)}>{more}</Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

Career.propTypes = {
  data: PropTypes.object.isRequired
}
