import { ExpandMore } from '@mui/icons-material'
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typo20Bold, Typo20RTF, Typo26Bold } from '../TYPO'
import {
  SolutionCarousel,
  SolutionPagination,
  SolutionSelector
} from './solutions'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const SolutionsContentMobile = ({ data, mask }) => {
  const { slug } = useParams()
  const theme = useTheme()
  const { primary } = theme.palette

  let initialSlug = 0

  switch (slug) {
    case undefined:
    case 'energiemonitoring':
      initialSlug = 0
      break
    case 'anlagenmonitoring':
      initialSlug = 1
      break
    case 'sicherheit':
      initialSlug = 2
      break
    case 'wohlbefinden':
      initialSlug = 3
      break
    default:
      // slug = undefined
      break
  }

  const [selData, setData] = useState({ selected: initialSlug })

  const changeSolution = (selected) =>
    setData((prev) => ({ ...prev, selected }))

  const slugData = [
    { value: 0, label: 'Energiemonitoring' },
    { value: 1, label: 'Anlagenmonitoring' },
    { value: 2, label: 'Sicherheit' },
    { value: 3, label: 'Wohlbefinden' }
  ]

  return (
    <Stack alignItems={'center'}>
      <Select
        value={selData.selected}
        sx={{ width: 300, alignItems: 'center' }}
        IconComponent={() => <ExpandMore sx={{ color: primary.main }} />}
      >
        {slugData.map((item, key) => {
          const { label, value } = item

          return (
            <MenuItem
              key={key}
              onClick={() => changeSolution(value)}
              value={value}
            >
              <Typo20Bold sx={{ color: primary.main }}>{label}</Typo20Bold>
            </MenuItem>
          )
        })}
      </Select>

      {data.map((item, key) => {
        const {
          detailedCaption: caption,
          detailedDescription: description,
          image
        } = item.attributes

        const { url } = image.data.attributes
        const { url: maskUrl } = mask.data.attributes

        return selData.selected === key ? (
          <Grid container sx={{ mt: 2 }} key={key}>
            <Grid item xs={12}>
              <Box
                component={'img'}
                src={`${BASE_URL}${url}`}
                sx={{
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: 320,
                  maskImage: `url(${BASE_URL}${maskUrl})`,
                  WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
                  maskSize: 320,
                  maskRepeat: 'no-repeat',
                  maskPosition: 'center'
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ pt: 10, px: 12 }}>
              <Typo26Bold sx={{ width: 1, mb: 3 }}>{caption}</Typo26Bold>
              <Typo20RTF sx={{ width: 1 }}>{description}</Typo20RTF>
            </Grid>
          </Grid>
        ) : null
      })}
    </Stack>
  )
}

SolutionsContentMobile.propTypes = {
  data: PropTypes.array,
  mask: PropTypes.object
}

const SolutionsContentDesktop = ({ data, mask }) => {
  const { slug } = useParams()

  let initialSlug = 0

  switch (slug) {
    case undefined:
    case 'energiemonitoring':
      initialSlug = 0
      break
    case 'anlagenmonitoring':
      initialSlug = 1
      break
    case 'sicherheit':
      initialSlug = 2
      break
    case 'wohlbefinden':
      initialSlug = 3
      break
    default:
      // slug = undefined
      break
  }

  const [selData, setData] = useState({ selected: initialSlug })

  const changeSolution = (selected) =>
    setData((prev) => ({ ...prev, selected }))

  return (
    <Stack alignItems={'center'}>
      <SolutionSelector
        selected={selData.selected}
        spacing={3}
        onChange={changeSolution}
      />

      <SolutionCarousel data={data} mask={mask} selected={selData.selected} />

      <SolutionPagination
        count={data.length}
        onChange={changeSolution}
        selected={selData.selected}
      />
    </Stack>
  )
}

SolutionsContentDesktop.propTypes = {
  data: PropTypes.array,
  mask: PropTypes.object
}

export const DZ_SolutionsContent = ({ data, mask }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('lg'))

  return isGtMD ? (
    <SolutionsContentDesktop data={data} mask={mask} />
  ) : (
    <SolutionsContentMobile data={data} mask={mask} />
  )
}

DZ_SolutionsContent.propTypes = {
  data: PropTypes.array,
  mask: PropTypes.object
}
