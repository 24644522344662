import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const Typo50 = ({ children, family, size, ...rest }) => (
  <Typography style={{ fontFamily: family, fontSize: size }} {...rest}>
    {children}
  </Typography>
)

Typo50.defaultProps = {
  family: 'Titillium Black',
  size: 50
}

Typo50.propTypes = {
  children: PropTypes.any.isRequired,
  family: PropTypes.string,
  size: PropTypes.number
}
