import { Button, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo26Bold } from '..'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

export const DZ_DownloadMedia = ({ file, item }) => {
  const { title, label } = item

  const handleDownload = (file) => {
    const { name, url } = file.data.attributes
    const link = document.createElement('a')
    link.href = `${BASE_URL}${url}`
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Stack alignItems={'center'} sx={{ textAlign: 'center' }}>
      <Typo26Bold sx={{ maxWidth: 500 }}>{title}</Typo26Bold>
      <Button onClick={() => handleDownload(file)} sx={{ my: 2 }}>
        {label}
      </Button>
    </Stack>
  )
}

DZ_DownloadMedia.propTypes = {
  file: PropTypes.object,
  item: PropTypes.object
}
