import { Paper } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { BigPic } from './BigPic'
import { HeaderLandingPage } from '../HeaderLandingPage'
import { Footer } from '../Footer'

export const PageWrapperLandingPage = ({ children, data }) => {
  if (!data) return null
  const { title } = data

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Paper elevation={0} sx={{ overflowX: 'hidden' }}>
      <HeaderLandingPage />

      <BigPic data={
        {
          'left': 1106,
          'top': 333,
          'maskMode': 'layer',
          'isCompact': true,
          'image': {
            'data': {
              'attributes': {
                'name': 'big_contact.jpg',
                'alternativeText': 'Header Gebäudeenergiegesetz',
                'caption': null,
                'width': 1920,
                'height': 420,
                'hash': 'big_contact_57b4364568',
                'ext': '.jpg',
                'mime': 'image/jpeg',
                'size': 50.92,
                'url': '/landingpages/geg/header.jpg',
                'previewUrl': null,
                'provider': 'local',
                'provider_metadata': null,
                'createdAt': '2023-09-28T22:39:21.067Z',
                'updatedAt': '2024-03-28T13:28:12.527Z',
                'zIndex' : 1
              }
            }
          },
          'mask': {
            'data': {
              'attributes': {
                'name': 'Gruppe maskieren 42.png',
                'alternativeText': null,
                'caption': null,
                'width': 1920,
                'height': 420,
                'hash': 'Gruppe_maskieren_42_aaefcd4852',
                'ext': '.png',
                'mime': 'image/png',
                'size': 26.35,
                'url': '/uploads/Gruppe_maskieren_42_aaefcd4852.png',
                'previewUrl': null,
                'provider': 'local',
                'provider_metadata': null,
                'createdAt': '2023-09-26T17:46:31.737Z',
                'updatedAt': '2024-03-28T13:28:11.953Z',
                'zIndex' : 1
              }
            }
          },
          'headline': {
            'color': 'white',
            'fontFamily': 'Titillium Black',
            'fontSize': 56,
            'width': 508,
            'text': 'Änderung des § 71A GEG zum **31.12.2024**',
            'bColor': '#F933FC',
            'zIndex' : 1
          },
          'description': {
            'color': 'black',
            'fontFamily': 'Titillium Regular',
            'fontSize': 26,
            'width': 576,
            'text': null,
            'bColor': '#F933FC'
          }
        }
      } 
      />

      {children}

      <Footer />
    </Paper>
  )
}

PageWrapperLandingPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  data: PropTypes.object
}
