import { Container, Grid, Stack, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import {
  PlekListItem20,
  PlekListItem20Bold,
  Typo20Bold,
  Typo32,
  Typo43
} from '..'
import { Typo26Bold } from '..'

const AssemblyJobMobile = ({ data, contact }) => {
  const theme = useTheme()
  const { grayscale, primary } = theme.palette

  const { requirements, role, title } = data.attributes

  const liItem = '&bull; '
  const roleData = role.split(liItem).filter((chunk) => chunk !== '')

  const requirementsData = requirements
    .split(liItem)
    .filter((chunk) => chunk !== '')

  const { email, telephone, username } = contact

  return (
    <Container sx={{ backgroundColor: grayscale[100], width: 1,}}>
      <Stack alignItems={'center'} sx={{ mt: 10, mb: 2,}}>
        <Typo26Bold>{title}</Typo26Bold>

        <Grid
          container
          sx={{ width: 1, borderBottom: `solid 3px ${grayscale[300]}`, pb: 3, mt: '40px'}}
        >
          <Grid item xs={12}>
            <Typo20Bold sx={{ color: primary.main, mb: 2}}>Ihr Profil</Typo20Bold>

            {roleData.map((role, key) => (
              <PlekListItem20 data={role} key={key} />
            ))}
          </Grid>

          <Grid item xs={12}>
            <Typo20Bold sx={{ color: primary.main, mb: 2}}>Ihre Aufgaben</Typo20Bold>

            {/*todo: fontsize=20*/}
            {requirementsData.map((requirement, key) => (
              <PlekListItem20 data={requirement} key={key} />
            ))}
          </Grid>
        </Grid>

        <Stack alignItems={'center'} sx={{ my: 2, width: 1 }}>
          <Typo20Bold color={{ color: primary.main }}>Wir bieten</Typo20Bold>
          <PlekListItem20Bold
            data={'Regelmäßige Aufträge in einem Markt mit großer Zukunft'}
          />
          <PlekListItem20Bold data={'Flexibilität bei der Umsetzung'} />
          <PlekListItem20Bold
            data={
              'Remote-Unterstützung bei allen Installationen, z. B. Prüfung, ob Sensoren korrekt installiert sind'
            }
          />
          <PlekListItem20Bold data={'Kostenfreie Schulungen – bei Bedarf'} />
        </Stack>

        <Typo32 sx={{ color: primary.main, mb: 1 }}>
          Interessiert? Gleich melden!
        </Typo32>

        <Typo20Bold sx={{ color: primary.main, mb: 5 }}>
          {username} - {email} - {telephone}
        </Typo20Bold>
      </Stack>
    </Container>
  )
}

AssemblyJobMobile.propTypes = {
  data: PropTypes.object,
  contact: PropTypes.object
}

const AssemblyJobDesktop = ({ data, contact }) => {
  const theme = useTheme()
  const { grayscale, primary } = theme.palette

  const { requirements, role, title } = data.attributes

  const liItem = '&bull; '
  const roleData = role.split(liItem).filter((chunk) => chunk !== '')

  const requirementsData = requirements
    .split(liItem)
    .filter((chunk) => chunk !== '')

  const { email, telephone, username } = contact

  return (
    <Container sx={{ backgroundColor: grayscale[100], width: 1 }}>
      <Stack alignItems={'center'} sx={{ mt: 10, mb: 2 }}>
        <Typo43>{title}</Typo43>

        <Grid
          container
          sx={{
            maxWidth: 1200,
            borderBottom: `solid 3px ${grayscale[300]}`,
            pb: 3,
            mt: '-40px'
          }}
          spacing={10}
        >
          <Grid item xs={6}>
            <Typo20Bold sx={{ color: primary.main, mb:2 }}>Ihr Profil</Typo20Bold>

            {/*todo: fontsize=20*/}
            {roleData.map((role, key) => (
              <PlekListItem20 data={role} key={key} />
            ))}
          </Grid>

          <Grid item xs={6}>
            <Typo20Bold sx={{ color: primary.main, mb: 2 }}>Ihre Aufgaben</Typo20Bold>

            {/*todo: fontsize=20*/}
            {requirementsData.map((requirement, key) => (
              <PlekListItem20 data={requirement} key={key} />
            ))}
          </Grid>
        </Grid>

        <Stack alignItems={'center'} sx={{ my: 2, maxWidth: 482 }}>
          <Typo20Bold color={{ color: primary.main }}>Wir bieten</Typo20Bold>
          <PlekListItem20Bold
            data={'Regelmäßige Aufträge in einem Markt mit großer Zukunft'}
          />
          <PlekListItem20Bold data={'Flexibilität bei der Umsetzung'} />
          <PlekListItem20Bold
            data={
              'Remote-Unterstützung bei allen Installationen, z. B. Prüfung, ob Sensoren korrekt installiert sind'
            }
          />
          <PlekListItem20Bold data={'Kostenfreie Schulungen – bei Bedarf'} />
        </Stack>

        <Typo32 sx={{ color: primary.main, mb: 1 }}>
          Interessiert? Gleich melden!
        </Typo32>

        <Typo20Bold sx={{ color: primary.main, mb: 5 }}>
          {username} - {email} - {telephone}
        </Typo20Bold>
      </Stack>
    </Container>
  )
}

AssemblyJobDesktop.propTypes = {
  data: PropTypes.object,
  contact: PropTypes.object
}

export const DZ_AssemblyJob = ({ data, contact }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <AssemblyJobDesktop data={data} contact={contact} />
  ) : (
    <AssemblyJobMobile data={data} contact={contact} />
  )
}

DZ_AssemblyJob.propTypes = {
  data: PropTypes.object,
  contact: PropTypes.object
}
