import { useTheme } from '@mui/material'
import React from 'react'
import { getSession } from '../api'
import { BigPic, PageWrapper } from '../components'
import {
  AtAGlance,
  Career,
  Consulting,
  HomeAdvantages,
  HomeProjects,
  HomeSolutions
} from '../components'
import { useHome } from '../hooks'
// assets
import SVG_ANIM_FEELING from '../img/animation/feeling.svg'
import SVG_ANIM_CONSUMPTION from '../img/animation/consumption.svg'
import SVG_ANIM_SECURITY from '../img/animation/security.svg'
import SVG_ANIM_MONITORING from '../img/animation/plant_monitoring.svg'
import SVG_ANIM_CORRELATION from '../img/animation/correlation.svg'

export const PageHome = () => {
  const theme = useTheme()
  const { palette } = theme
  const { primary } = palette

  const { data } = useHome()
  if (!data) return null

  const blobData = [
    // feeling
    { r: 12, fill: primary.light, left: 1220, top: 350 },
    // correlation
    { r: 12, fill: primary.light, left: 1550, top: 300 },
    // consumption
    { r: 12, fill: primary.light, left: 1300, top: 500 },
    // security
    { r: 12, fill: primary.light, left: 1450, top: 450 },
    // monitoring
    { r: 12, fill: primary.light, left: 1330, top: 700 }
  ]

  const {
    AtAGlance: atAGlanceData,
    solutions: solutionsData,
    Advantages: advantagesData,
    Consulting: consultingData,
    Projects: projectsData,
    Career: careerData,
    HomeContent
  } = data

  return (
    <PageWrapper data={{ title: 'KARMA' }}>
      {HomeContent.map((item, key) => {
        const { __component } = item

        switch (__component) {
          case 'common.big-pic':
            return <BigPic data={item} key={key} />

          default:
            return null
        }
      })}

      <div
        id={'animation-container'}
        style={{
          display: getSession('animation') === false ? 'none' : 'visible'
        }}
      >
        {blobData.map((item, key) => {
          const { r, fill, left, top } = item

          return (
            <svg
              style={{ position: 'absolute', left, top }}
              key={key}
              width={r * 2}
              height={r * 2}
              xmlns={'http://www.w3.org/2000/svg'}
            >
              <circle cx={r} cy={r} r={r} fill={fill} />
            </svg>
          )
        })}

        <div id={'feelingBar1'}></div>
        <div id={'feelingBar2'}></div>
        <div
          id={'feelingIcon'}
          style={{ backgroundImage: `url(${SVG_ANIM_FEELING})` }}
        />

        <div id={'consumptionBar1'}></div>
        <div
          id={'consumptionIcon'}
          style={{ backgroundImage: `url(${SVG_ANIM_CONSUMPTION})` }}
        />

        <div id={'securityBar1'}></div>
        <div id={'securityBar2'}></div>
        <div id={'securityBar3'}></div>
        <div
          id={'securityIcon'}
          style={{ backgroundImage: `url(${SVG_ANIM_SECURITY})` }}
        />

        <div id={'correlationBar1'}></div>
        <div id={'correlationBar2'}></div>
        <div
          id={'correlationIcon'}
          style={{ backgroundImage: `url(${SVG_ANIM_CORRELATION})` }}
        />

        <div id={'monitoringBar1'}></div>
        <div id={'monitoringBar2'}></div>
        <div
          id={'monitoringIcon'}
          style={{ backgroundImage: `url(${SVG_ANIM_MONITORING})` }}
        />
      </div>

      {/* AtAGlance */}
      <AtAGlance data={atAGlanceData} />

      {/* Solutions */}
      <HomeSolutions data={solutionsData.data} />

      {/* Advantages */}
      <HomeAdvantages data={advantagesData} />

      {/* Consulting */}
      <Consulting data={consultingData} />

      {/* Projects */}
      <HomeProjects data={projectsData} />

      {/* Career */}
      <Career data={careerData} />
    </PageWrapper>
  )
}
