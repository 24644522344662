import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { DZ_GotoPage } from '..'

export const SolutionSelector = ({ onChange, selected, ...rest }) => {
  const data = [
    {
      id: 0,
      label: 'Energiemonitoring',
      isAddOn: false,
      href: '/loesungen/energiemonitoring'
    },
    {
      id: 1,
      label: 'Anlagenmonitoring',
      isAddOn: false,
      href: '/loesungen/anlagenmonitoring'
    },
    {
      id: 2,
      label: 'Sicherheit',
      isAddOn: true,
      href: '/loesungen/sicherheit'
    },
    {
      id: 3,
      label: 'Wohlbefinden',
      isAddOn: true,
      href: '/loesungen/wohlbefinden'
    }
  ]

  return (
    <Stack direction={'row'} {...rest}>
      {data.map((item, key) => {
        const { id, href, label } = item
        const backgroundColor = id === selected ? 'primary.main' : 'white'
        const color = id === selected ? 'white' : 'primary.main'

        return (
          <DZ_GotoPage
            href={href}
            key={key}
            onClick={() => onChange(id)}
            sx={{ backgroundColor, color }}
            title={label}
          />
        )
      })}
    </Stack>
  )
}

SolutionSelector.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.number
}
