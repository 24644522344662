import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useBearer } from '../hooks'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const themeDataContext = createContext({})

export const ThemeDataProvider = ({ children }) => {
  const value = getValue()

  return (
    <themeDataContext.Provider value={value}>
      {children}
    </themeDataContext.Provider>
  )
}
ThemeDataProvider.propTypes = { children: PropTypes.any }

export const useThemeData = () => useContext(themeDataContext)

const getValue = () => {
  const bearer = useBearer()

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!bearer) return
        setLoading(true)

        const res = await fetch(`${BASE_URL}/api/theme-data`, {
          headers: {
            Authorization: `Bearer ${bearer}`,
            'Content-Type': 'application/json'
          }
        })

        if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`)

        const json = await res.json()
        if (json.error === undefined) {
          setData(json.data.attributes)
          setError(null)
        } else {
          setError(json.error)
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(error.message)
        console.error(error.message)
      }
    }

    fetchData()
  }, [bearer])

  return { data, error, loading }
}
