import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SVG_Plektrum, Typo20 } from '..'

const PlekListItem = ({ data }) => {
  const { description, title } = data

  return (
    <Card elevation={0}>
      <CardHeader
        avatar={<SVG_Plektrum />}
        sx={{ pb: 0, '& .MuiTypography-root': { fontSize: 20 } }}
        title={title}
      />

      <CardContent sx={{ pt: 0 }}>
        <Typo20 sx={{ ml: 4 }}>{description}</Typo20>
      </CardContent>
    </Card>
  )
}

PlekListItem.propTypes = {
  data: PropTypes.object.isRequired
}

const WhatToExpectMobile = ({ data }) => {
  const { PlekListItemLeft, PlekListItemRight } = data

  return (
    <Stack alignItems={'center'}>
      <Grid container>
        <Grid item xs={12}>
          {PlekListItemLeft.map((item, key) => (
            <PlekListItem data={item} key={key} />
          ))}
        </Grid>

        <Grid item xs={12}>
          {PlekListItemRight.map((item, key) => (
            <PlekListItem data={item} key={key} />
          ))}
        </Grid>
      </Grid>
    </Stack>
  )
}

WhatToExpectMobile.propTypes = {
  data: PropTypes.object.isRequired
}

const WhatToExpectDesktop = ({ data }) => {
  const { PlekListItemLeft, PlekListItemRight } = data

  return (
    <Stack alignItems={'center'}>
      <Grid container sx={{ maxWidth: 1200 }} spacing={10}>
        <Grid item xs={6}>
          {PlekListItemLeft.map((item, key) => (
            <PlekListItem data={item} key={key} />
          ))}
        </Grid>

        <Grid item xs={6}>
          {PlekListItemRight.map((item, key) => (
            <PlekListItem data={item} key={key} />
          ))}
        </Grid>
      </Grid>
    </Stack>
  )
}

WhatToExpectDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

export const DZ_WhatToExpect = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <WhatToExpectDesktop data={data} />
  ) : (
    <WhatToExpectMobile data={data} />
  )
}

DZ_WhatToExpect.propTypes = {
  data: PropTypes.object.isRequired
}
