import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { PlekListItem26, SVGPic, Typo20, Typo20Bold } from '..'
import { rtfBoldedText } from '../../api'
import { PlekListItem20 } from '.'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const PhaseText = ({ phase }) => {
  const { caption, excerpt, features, icon, title } = phase.attributes
  const { url: iconUrl } = icon.data.attributes
  const liItem = '&bull; '
  const featureList = features.split(liItem).filter((chunk) => chunk !== '')
  const svg = <SVGPic src={`${BASE_URL}${iconUrl}`} />

  return (
    <Card elevation={0} sx={{ maxWidth: 600 }}>
      <CardHeader avatar={svg} title={title} />

      <CardContent>
        <Stack sx={{ maxWidth: 532 }}>
          <Typo20>{excerpt}</Typo20>

          <Typo20Bold sx={{ color: 'primary.main', my: 2 }}>
            {caption}
          </Typo20Bold>
        </Stack>

        <List>
          {featureList.map((feature, key) => (
            <PlekListItem26 data={rtfBoldedText(feature)} key={key} />
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

PhaseText.propTypes = {
  phase: PropTypes.object.isRequired
}

const PhaseImage = ({ image, mask }) => {
  const { url, width, height } = image.data.attributes
  const { url: maskUrl } = mask.data.attributes

  const maskImage = `url(${`${BASE_URL}${maskUrl}`})`
  const WebkitMaskImage = maskImage

  return (
    <Box
      component={'img'}
      draggable={false}
      src={`${BASE_URL}${url}`}
      sx={{
        maskImage,
        WebkitMaskImage,
        maskPosition: '50% 50%',
        maskRepeat: 'no-repeat',
        maskSize: { xl: 900, md: 700 },
        width,
        height,
        maxWidth: { xl: 880, md: 680 },
        maxHeight: { xl: 680, md: 480 }
      }}
    />
  )
}

PhaseImage.propTypes = {
  image: PropTypes.object.isRequired,
  mask: PropTypes.object.isRequired
}

const PhasesDesktop = ({ data }) =>
  data.map((phase, key) => {
    const { image, mask } = phase.attributes
    const isEven = key % 2 === 0

    return (
      <Grid container key={key} spacing={10}>
        <Grid
          item
          xs={6}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          {isEven ? (
            <PhaseText phase={phase} />
          ) : (
            <PhaseImage image={image} mask={mask} />
          )}
        </Grid>

        <Grid
          item
          xs={6}
          sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left' }}
        >
          {isEven ? (
            <PhaseImage image={image} mask={mask} />
          ) : (
            <PhaseText phase={phase} />
          )}
        </Grid>
      </Grid>
    )
  })

PhasesDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

const PhasesMobile = ({ data }) =>
  data.map((phase, key) => {
    const theme = useTheme()
    const { primary } = theme.palette
    const { image, mask } = phase.attributes

    const { url } = image.data.attributes
    const { url: maskUrl } = mask.data.attributes

    const maskImage = `url(${`${BASE_URL}${maskUrl}`})`
    const WebkitMaskImage = maskImage
    const { caption, excerpt, features, icon, title } = phase.attributes
    const { url: iconUrl } = icon.data.attributes
    const liItem = '&bull; '
    const featureList = features.split(liItem).filter((chunk) => chunk !== '')
    const svg = <SVGPic src={`${BASE_URL}${iconUrl}`} />

    return (
      <Grid container key={key}>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${url}`}
            sx={{
              maskImage,
              WebkitMaskImage,
              maskPosition: '50% 50%',
              maskRepeat: 'no-repeat',
              maskSize: 370,
              width: 1
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ alignItems: 'center', display: 'flex', justifyContent: 'left' }}
        >
          <Card elevation={0}>
            <CardHeader
              avatar={svg}
              title={title}
              sx={{ '& .MuiTypography-root': { fontSize: 26 } }}
            />

            <CardContent>
              <Stack>
                <Typo20>{excerpt}</Typo20>

                <Typo20Bold sx={{ color: primary.main, my: 2 }}>
                  {caption}
                </Typo20Bold>
              </Stack>

              <List>
                {featureList.map((feature, key) => (
                  <PlekListItem20 data={rtfBoldedText(feature)} key={key} />
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  })

PhasesMobile.propTypes = {
  data: PropTypes.array.isRequired
}

export const DZ_ConsultingPhases = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? <PhasesDesktop data={data} /> : <PhasesMobile data={data} />
}

DZ_ConsultingPhases.propTypes = {
  data: PropTypes.array.isRequired
}
