import PropTypes from 'prop-types'
import React from 'react'

export const SVG_KarmaLogo = ({ width, height, ...rest }) => {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
    >
      <defs>
        <linearGradient id={'a'} x1={'50%'} x2={'50%'} y1={'0%'} y2={'100%'}>
          <stop stopColor={'#F710ED'} offset={'0%'} />
          <stop stopColor={'#8004A9'} offset={'100%'} />
        </linearGradient>

        <path
          id={'b'}
          d={'M28.7542797.0533H.6345133v40.24644h28.1197664V.0533z'}
        />

        <path
          id={'d'}
          d={'M40.2499535.0533H1.24352116v40.24644H40.2499535V.0533z'}
        />

        <path
          id={'f'}
          d={'M32.1837226 40.29961V.0533H.28016964v40.24631H32.1837226z'}
        />
      </defs>

      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'url(#a)'}
          d="M56.2017197 18.4215902c12.0735177-7.1545539 24.485288-9.36089056 37.9983456-10.6283884 19.1138907-1.79125728 37.5276767-.43995792 56.3203577 2.9592408 29.172314 5.275567 64.350578 17.3731006 86.89288 38.8825913 27.356502 26.1028611 26.392875 56.8017111 10.023026 88.0138451-14.160719 27.001109-39.500816 53.567497-61.891036 73.192763-55.059122 48.257885-125.0957508 81.981446-147.8648661-8.742854-7.2409641-28.853908-9.0030238-66.327586-8.634617-95.676446.2281238-18.2058784.860053-39.9392763 5.7502931-57.8583961 3.6276928-13.287777 9.0475997-22.8188893 21.4056167-30.1423557"
        />

        <path
          fill={'#1B022B'}
          d="M162.254615 12.3423866c16.880834 9.1264558 31.4293 20.4817544 45.157508 33.7525468 21.3084 20.5997521 43.817052 50.2158289 50.591398 80.6473996 8.219629 36.930615-9.647875 61.952653-40.604151 78.84466-26.778979 14.612031-62.607019 22.650287-92.123255 26.549451-72.5837848 9.586646-149.532241-1.248152-118.01514923-89.429041C17.2849026 114.662005 36.6540312 82.4853813 53.2767322 58.2552377c10.3122069-15.0302672 22.9200919-32.7731671 36.9470519-44.974115C100.625093 4.23333193 110.427586-.69109962 124.769022.0785067c14.011236.75125113 25.551212 5.81072434 37.485593 12.2638799z"
        />

        <path
          fill={'#FFF'}
          d="M59.4241935 110.30013l-10.3049854 18.4951-6.63532273.2327v-18.7278H37.9996096v40.2467h4.48427577v-17.5643l6.69388113-.2327 10.7109907 17.797h5.2988888l-12.167144-20.007 11.526905-20.2397m16.6652136 0l-11.0623414 40.2467h4.4257174l2.9097041-10.3519h17.2331004l2.9110055 10.3519h4.4231147l-11.0597387-40.2467h-9.780562zm3.3755691 3.8389h3.0281224l6.1122006 22.0428H78.4759891l6.1122006-22.0428z"
        />

        <g transform={'translate(108.27 110.247)'}>
          <mask id={'c'} fill={'#fff'}>
            <use xlinkHref={'#b'} />
          </mask>

          <path
            fill={'#FFF'}
            d="M15.5382888 4.00764H5.11748777v16.9247H15.5968472c6.0536422 0 8.208593-3.6049 8.208593-8.6658 0-5.525-2.6208158-8.2589-8.2671514-8.2589zM5.11748777 24.88694v15.4128H.6345133V.05304h14.9037755c8.4415255 0 12.8646402 3.7219 12.8646402 12.2135 0 5.9332-2.3280236 10.062-7.3926786 11.6909l7.7440293 16.3423h-4.9488395l-7.2781643-15.4128H5.11748777z"
            mask={'url(#c)'}
          />
        </g>

        <g transform={'translate(143.405 110.247)'}>
          <mask id={'e'} fill={'#fff'}>
            <use xlinkHref={'#d'} />
          </mask>

          <path
            fill={'#FFF'}
            d="M1.24352116.05304h8.0914761l11.41108944 34.0236L32.1571761.05304h8.0927774v40.2467h-4.4842757V4.82144h-.8146131l-11.7012791 34.0249h-5.0073978L6.5411087 4.82144h-.8159144v35.4783H1.24352116"
            mask={'url(#e)'}
          />
        </g>

        <g transform={'translate(190.252 110.247)'}>
          <mask id={'g'} fill={'#fff'}>
            <use xlinkHref={'#f'} />
          </mask>

          <path
            fill={'#FFF'}
            d="M8.60587954 25.93461H23.8584031l-6.1122006-22.0428h-3.0281224l-6.11220056 22.0428zM11.342511.05291h9.780562l11.06104 40.2467h-4.424416l-2.9110055-10.3519H7.61559113l-2.91100548 10.3519h-4.424416L11.342511.05291z"
            mask={'url(#g)'}
          />
        </g>
      </g>
    </svg>
  )
}

SVG_KarmaLogo.defaultProps = {
  width: 260,
  height: 260
}

SVG_KarmaLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
