import React from 'react'
import { getPageTitle, parseDZData } from '../api'
import { DynamicZoneRenderer, PageWrapper } from '../components'
import { useEKB } from '../hooks'

export const PageEKB = () => {
  const { data } = useEKB()
  if (!data) return null

  parseDZData(data)

  const parsed = data[Object.keys(data)[0]]
  const title = getPageTitle(data)

  return (
    <PageWrapper data={{ title }}>
      <DynamicZoneRenderer data={parsed} />
    </PageWrapper>
  )
}
