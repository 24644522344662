// modules
import { Alert, Paper } from '@mui/material'
import React from 'react'

export const Page404 = () => {
  return (
    <Paper elevation={0}>
      <Alert>NOT FOUND</Alert>
    </Paper>
  )
}
