import React, { useState } from 'react'
import {
  BigPic,
  DZ_AssemblyJob,
  DZ_ConsultingPhases,
  DZ_Contact,
  DZ_DownloadMedia,
  DZ_GotoPage,
  DZ_OurLocations,
  DZ_OurStrengths,
  DZ_Projects,
  DZ_Principle,
  DZ_SolutionsContent,
  DZ_SectionHeader,
  DZ_TextBlock,
  DZ_TwoRows,
  DZ_WhatToExpect
} from './'
import { Typo32 } from '../TYPO'
import { Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'

export const DynamicZoneRenderer = ({ data }) => {
  const location = useLocation()
  const [sectionBgColor, setSectionBgColor] = useState('transparent')
  const [jobContact, setJobContact] = useState(null)

  return data.map((item, key) => {
    const {
      __component,
      advantagesLeft,
      advantagesRight,
      color,
      consultings,
      contact,
      description,
      descriptionWidth,
      file,
      href,
      jobs,
      locations,
      principles,
      projects,
      slogan,
      sloganWidth,
      solutions,
      solutionsMask,
      strengths,
      text,
      title
    } = item

    switch (__component) {
      case 'common.big-pic':
        return <BigPic data={item} key={key} />

      case 'common.consultings':
        return <DZ_ConsultingPhases data={consultings.data} key={key} />

      case 'common.contact':
        switch (location.pathname) {
          case '/montagepartner':
            if (jobContact === null) setJobContact(contact.data.attributes)

            return null
          default:
            return <DZ_Contact data={contact.data} key={key} />
        }

      case 'common.download-media':
        return <DZ_DownloadMedia file={file} item={item} key={key} />

      case 'common.goto-page':
        return (
          <DZ_GotoPage href={href} key={key} sx={{ mb: 10 }} title={title} />
        )

      case 'common.our-strengths':
        return <DZ_OurStrengths data={strengths.data} key={key} />

      case 'common.our-locations':
        return <DZ_OurLocations data={locations.data} key={key} />

      case 'common.principles':
        return <DZ_Principle data={principles.data} key={key} />

      case 'common.projects':
        return <DZ_Projects data={projects.data} key={key} />

      case 'common.simple-text':
        return (
          <Typo32 key={key} sx={{ my: 3, textAlign: 'center' }}>
            {text} 
          </Typo32>
        )

      case 'common.solutions-content':
        return (
          <DZ_SolutionsContent
            data={solutions.data}
            key={key}
            mask={solutionsMask}
          />
        )

      case 'common.text-block':
        return (
          <DZ_TextBlock
            data={{ description, slogan, title }}
            key={key}
            sloganWidth={sloganWidth || undefined}
            sx={{ maxWidth: descriptionWidth || undefined }}
          />
        )

      case 'common.two-rows':
        return (
          <DZ_TwoRows data={{ advantagesLeft, advantagesRight }} key={key} />
        )

      case 'common.section-header':
        return <DZ_SectionHeader title={title} key={key} />

      case 'common.section-background':
        if (color !== sectionBgColor) setSectionBgColor(color)

        return <Stack key={key} />

      case 'common.what-to-expect':
        return <DZ_WhatToExpect data={item} key={key} />

      case 'common.jobs':
        return (
          <DZ_AssemblyJob contact={jobContact} data={jobs.data[0]} key={key} />
        )

      default:
        console.error(`unhandled component: ${__component}`)
        return null
    }
  })
}
