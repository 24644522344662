import { createTheme } from '@mui/material/styles'

/* XD palette

#6E23BA
#A81398 (primary)
#F933FC 1x App.css a:hover
#1E011D 0x
#27143D 0x
#35154F 2x Home.cmp Typo50
--
#ECECED 3x background
#DADADA 1x footer
#C5C6C6 1x assembly
#B1B2B3 0x
#9C9D9D 0x
#878786 2x
#FFFFFF

*/

export const theme = createTheme({
  info: { backgroundColor: '#00D3EE' },
  primary: { backgroundColor: '#AF2CC5' },
  error: { backgroundColor: '#EB3573' },
  // error: { backgroundColor: '#F55A4E' },
  success: { backgroundColor: '#5CB860' },
  warning: { backgroundColor: '#FFA21A' },

  spacing: 9.6,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },

  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:before': { backgroundColor: '#C5C5C6' },
          '&:after': { backgroundColor: '#C5C5C6' }
        }
      }
    },

    MuiBox: {
      defaultProps: { draggable: false },
      styleOverrides: {
        root: () => ({ draggable: false })
      }
    },

    MuiContainer: {
      defaultProps: { maxWidth: false },
      styleOverrides: {
        root: () => ({ maxWidth: false })
      }
    },

    MuiCardHeader: {
      styleOverrides: {
        title: ({ theme: t }) => ({
          color: t.palette.primary.main,
          fontSize: 32,
          fontFamily: 'Titillium Bold',
          maxWidth: 350
        })
      }
    },

    MuiButton: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: ({ ownerState, theme: t }) => ({
          padding: '5px 40px',
          borderColor: t.palette.primary.main,
          borderRadius: 8,
          borderWidth: 3,
          marginTop: 40,
          ...(ownerState.color === 'primary' && {
            '&:hover': {
              backgroundColor: t.palette.primary.main,
              borderWidth: 3,
              color: 'white'
            }
          }),
          ...(ownerState.color === 'secondary' && {
            '&:hover': {
              backgroundColor: t.palette.secondary.main,
              borderWidth: 3,
              color: '#6E23BA'
            }
          })
        })
      }
    },

    MuiFormLabel: {
      defaultProps: {
        sx: { color: 'primary.main', fontSize: 20, fontWeight: 900, mb: 1 }
      }
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        inputProps: { style: { fontSize: 20, padding: '5px 15px' } },
        sx: { mb: 2.4 }
      }
    }
  },

  palette: {
    // dark mode
    mode: 'light',
    primary: {
      main: '#A81398',
      light: '#F933FC',
      dark: '#6E23BA',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#27143D',
      light: '#35154F',
      dark: '#1E011D',
      contrastText: '#6E23BA'
    },

    grayscale: {
      100: '#ECECED',
      200: '#DADADA',
      300: '#C5C6C6',
      400: '#B1B2B3',
      500: '#9C9D9D',
      600: '#878786'
    }
  },

  props: {
    MuiButton: { color: 'primary' },
    MuiCheckbox: { color: 'primary' },
    MuiRadio: { color: 'primary' },
    MuiSelect: { color: 'primary' },
    MuiSwitch: { color: 'primary' },
    MuiTextField: { color: 'primary' }
  },

  typography: {
    button: { textTransform: 'none' },
    fontFamily: 'Titillium Regular',
    fontSize: 26
  }
})
