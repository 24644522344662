import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { PlekListItem26, SVG_Plektrum, Typo20Bold } from '..'

const AdvantagesMobile = ({ data }) => {
  const { advantagesLeft, advantagesRight } = data
  const left = advantagesLeft
    ? advantagesLeft.split('&bull; ').filter((chunk) => chunk !== '')
    : []
  const right = advantagesLeft
    ? advantagesRight.split('&bull; ').filter((chunk) => chunk !== '')
    : []
  const theme = useTheme()
  const { grayscale } = theme.palette
  const merged = [...left, ...right]

  return (
    <Container
      sx={{
        backgroundColor: grayscale[100],
        mt: 10,
        width: 1
      }}
    >
      <List sx={{ width: 1 }}>
        {merged.map((item, key) => (
          <ListItem alignItems={'flex-start'} key={key} sx={{ p: 0, mb: 2 }}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <SVG_Plektrum />
            </ListItemIcon>

            <Typo20Bold>{item}</Typo20Bold>
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

AdvantagesMobile.propTypes = {
  data: PropTypes.object.isRequired
}

const AdvantagesDesktop = ({ data }) => {
  const { advantagesLeft, advantagesRight } = data
  const left = advantagesLeft
    ? advantagesLeft.split('&bull; ').filter((chunk) => chunk !== '')
    : []
  const right = advantagesRight
    ? advantagesRight.split('&bull; ').filter((chunk) => chunk !== '')
    : []
  const theme = useTheme()
  const { grayscale } = theme.palette

  return (
    <Container
      sx={{
        alignItems: 'center',
        backgroundColor: grayscale[100],
        display: 'flex',
        justifyContent: 'center',
        mt: 10,
        width: 1
      }}
    >
      <Stack
        direction={'row'}
        spacing={10}
        sx={{
          maxWidth: 1400,
          p: 10,
          whiteSpace: 'pre-line',
          width: 1,
          wordWrap: 'break-word'
        }}
      >
        <List sx={{ maxWidth: 500 }}>
          {left.map((item, key) => (
            <PlekListItem26 data={item} key={key} />
          ))}
        </List>

        <List sx={{ maxWidth: 700 }}>
          {right.map((item, key) => (
            <PlekListItem26 data={item} key={key} />
          ))}
        </List>
      </Stack>
    </Container>
  )
}

AdvantagesDesktop.propTypes = {
  data: PropTypes.object.isRequired
}

export const DZ_TwoRows = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <AdvantagesDesktop data={{ data }} />
  ) : (
    <AdvantagesMobile data={{ data }} />
  )
}

DZ_TwoRows.propTypes = {
  data: PropTypes.object.isRequired
}
