import {Box} from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";

export const OverVioletGradient = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: 'url("/landingpages/geg/checklist.svg")',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        ...props.sx
      }}
    >
      <code></code>
      {props.children}
    </Box>
  )
}

OverVioletGradient.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  sx: PropTypes.object
}
