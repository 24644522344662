// modules
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
// scripts
import { Typo20, Typo20Bold, Typo32 } from '../..'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const ContactPersonDesktop = ({ data }) => {
  const { email, mask, picture, telephone, username } = data.attributes

  const src = `${BASE_URL}${
    picture.data === null ? null : picture.data.attributes.url
  }`
  const maskImg = `url(${BASE_URL}${
    mask.data === null ? null : mask.data.attributes.url
  })`

  return (
    <Stack alignItems={'center'}>
      <Box
        component={'img'}
        src={src}
        sx={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          //width: 892,
          //height: 595,
          maskImage: maskImg,
          WebkitMaskImage: maskImg,
          //maskSize: 450,
          maskRepeat: 'no-repeat',
          maskPosition: '50% 0%'
        }}
      />

      <Typo32>Ihr Ansprechpartner</Typo32>

      <Typo20Bold sx={{ color: 'primary.main', mt: 1 }}>{username}</Typo20Bold>

      <Typo20 sx={{ color: 'primary.main', textDecoration: 'underline' }}>
        {email}
      </Typo20>

      <Typo20 sx={{ color: 'primary.main', mb: 3.6 }}>
        Telefon {telephone}
      </Typo20>

      <Typo20>KARMA GmbH</Typo20>
      <Typo20>Wilhelm-Bleyle-Straße 1</Typo20>
      <Typo20>71636 Ludwigsburg</Typo20>
    </Stack>
  )
}

ContactPersonDesktop.propTypes = {
  data: PropTypes.object
}

const ContactPersonMobile = ({ data }) => {
  const { email, mask, picture, telephone, username } = data.attributes

  const src = `${BASE_URL}${
    picture.data === null ? null : picture.data.attributes.url
  }`
  const maskImg = `url(${BASE_URL}${
    mask.data === null ? null : mask.data.attributes.url
  })`

  return (
    <Stack alignItems={'center'} sx={{ mt: 6 }}>
      <Box
        component={'img'}
        src={src}
        sx={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: 420,
          maskImage: maskImg,
          WebkitMaskImage: maskImg,
          maskSize: 320,
          maskRepeat: 'no-repeat',
          maskPosition: '50% 0%'
        }}
      />

      <Typo32>Ihr Ansprechpartner</Typo32>

      <Typo20Bold sx={{ color: 'primary.main', mt: 1 }}>{username}</Typo20Bold>

      <Typo20 sx={{ color: 'primary.main', textDecoration: 'underline' }}>
        {email}
      </Typo20>

      <Typo20 sx={{ color: 'primary.main', mb: 3.6 }}>
        Telefon {telephone}
      </Typo20>

      <Typo20>KARMA GmbH</Typo20>
      <Typo20>Wilhelm-Bleyle-Straße 1</Typo20>
      <Typo20>71636 Ludwigsburg</Typo20>
    </Stack>
  )
}

ContactPersonMobile.propTypes = {
  data: PropTypes.object
}

export const ContactPerson = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <ContactPersonDesktop data={data} />
  ) : (
    <ContactPersonMobile data={data} />
  )
}

ContactPerson.propTypes = {
  data: PropTypes.object
}
