import React, { useEffect, useState } from 'react'
import { DynamicZoneRenderer, PageWrapper } from '../components'
import { useImpressum } from '../hooks'

export const PageImpressum = () => {
  const [pageData, setPageData] = useState({
    content: [],
    description: '',
    title: '',
    image_url: ''
  })
  const { content, description, title } = pageData
  const { data, loading } = useImpressum()

  useEffect(() => {
    if (loading === true || !data) return

    const { ImpressumContent: content } = data
    const { title } = content.find((f) => f.__component === 'common.text-block')
    const { image } = content.find((f) => f.__component === 'common.big-pic')

    setPageData({
      content,
      description,
      title,
      image_url: image.data.attributes.url
    })
  }, [data, loading])

  return loading ? null : (
    <PageWrapper data={{ title }}>
      <DynamicZoneRenderer data={content} />
    </PageWrapper>
  )
}
