import { Paper, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { BigPic } from './BigPic'
import { Header } from '../Header'
import { Footer } from '../Footer'

export const PageWrapper = ({ children, data }) => {
  if (!data) return null
  const { BigPic: bpData, title } = data

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Paper elevation={0} sx={{ overflowX: 'hidden' }}>
      <Header />

      <BigPic data={bpData} />

      <Stack alignItems={'center'}>{children}</Stack>

      <Footer />
    </Paper>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object
}
