import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo20, Typo43 } from '..'
import { Typo26Bold } from '..'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const OurLocationsMobile = ({ data }) => {
  return (
    <Stack alignItems={'center'} sx={{ my: 4.8 }}>
      <Typo26Bold>Unsere Standorte</Typo26Bold>

      <Grid container sx={{ maxWidth: 1 }}>
        {data.map((item, key) => {
          const { city, description, image, mask } = item.attributes
          const { url } = image.data.attributes
          const { url: maskUrl } = mask.data.attributes
          const src = `${BASE_URL}${url}`

          return (
            <Grid
              item
              xs={12}
              key={key}
              sx={{ width: 1, display: 'flex', justifyContent: 'center' }}
            >
              <Stack>
                <Box
                  component={'img'}
                  draggable={false}
                  src={src}
                  sx={{
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    maskImage: `url(${BASE_URL}${maskUrl})`,
                    WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center',
                    maskSize: 450,
                    height: 350,
                    width: 1,
                    ml: -2
                  }}
                />
                <Card elevation={0} sx={{ width: 1, maxWidth: 1, mx: 'auto' }}>
                  <CardHeader
                    sx={{ '& .MuiTypography-root': { color: 'black' } }}
                    title={city}
                  />
                  <CardContent>
                    <Typo20>{description}</Typo20>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

OurLocationsMobile.propTypes = {
  data: PropTypes.array.isRequired
}

const OurLocationsDesktop = ({ data }) => {
  return (
    <Stack alignItems={'center'} sx={{ my: 4.8 }}>
      <Typo43>Unsere Standorte</Typo43>

      <Grid container>
        {data.map((item, key) => {
          const { city, description, image, mask } = item.attributes
          const { url } = image.data.attributes
          const { url: maskUrl } = mask.data.attributes
          const src = `${BASE_URL}${url}`

          return (
            <Grid
              item
              md={6}
              key={key}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  component={'img'}
                  //draggable={false}
                  src={src}
                  sx={{
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    maskImage: `url(${BASE_URL}${maskUrl})`,
                    WebkitMaskImage: `url(${BASE_URL}${maskUrl})`,
                    maskRepeat: 'no-repeat',
                    maskPosition: 'center',
                    //margin: 'auto'
                    //maskSize: 600,
                    height: 500
                  }}
                />
                <Card elevation={0} sx={{ maxWidth: 580, mx: 'auto' }}>
                  <CardHeader
                    sx={{ '& .MuiTypography-root': { color: 'black' } }}
                    title={city}
                  />
                  <CardContent>
                    <Typo20>{description}</Typo20>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

OurLocationsDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

export const DZ_OurLocations = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <OurLocationsDesktop data={data} />
  ) : (
    <OurLocationsMobile data={data} />
  )
}

DZ_OurLocations.propTypes = {
  data: PropTypes.array.isRequired
}
