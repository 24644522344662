import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo20 } from '..'

const { REACT_APP_BASE_URL: BASE_URL } = process.env

const PrincipleText = ({ principle }) => {
  const { description, title } = principle.attributes

  return (
    <Card elevation={0} sx={{ maxWidth: 508 }}>
      <CardHeader
        title={title}
        sx={{ '& .MuiTypography-root': { color: 'black' } }}
      />
      <CardContent>
        <Typo20>{description}</Typo20>
      </CardContent>
    </Card>
  )
}

PrincipleText.propTypes = {
  principle: PropTypes.object.isRequired
}

const PrincipleIcon = ({ principle }) => {
  const { icon } = principle.attributes
  const { url } = icon.data.attributes

  return (
    <Box
      component={'img'}
      draggable={false}
      src={`${BASE_URL}${url}`}
      sx={{ width: 510, height: 461 }}
    />
  )
}

PrincipleIcon.propTypes = {
  principle: PropTypes.object.isRequired
}

const ThePrinciplesMobile = ({ data }) =>
  data.map((principle, key) => {
    const { icon } = principle.attributes
    const { url } = icon.data.attributes

    return (
      <Grid container alignItems={'center'} key={key}>
        <Grid item xs={12} sx={{ mx: 2 }}>
          <Box
            component={'img'}
            draggable={false}
            src={`${BASE_URL}${url}`}
            sx={{ width: 1, height: 'auto', maxWidth: 510, maxHeight: 461 }}
          />
        </Grid>
        <Grid item xs={12}>
          <PrincipleText principle={principle} />
        </Grid>
      </Grid>
    )
  })

ThePrinciplesMobile.propTypes = {
  data: PropTypes.array.isRequired
}

const ThePrinciplesDesktop = ({ data }) =>
  data.map((principle, key) => {
    const isEven = key % 2 === 0

    return isEven ? (
      <Stack
        alignItems={'center'}
        direction={'row'}
        key={key}
        spacing={10}
        sx={{ mt: 10 }}
      >
        <PrincipleIcon principle={principle} />
        <PrincipleText principle={principle} />
      </Stack>
    ) : (
      <Stack
        alignItems={'center'}
        direction={'row'}
        key={key}
        spacing={10}
        sx={{ mt: 10 }}
      >
        <PrincipleText principle={principle} />
        <PrincipleIcon principle={principle} />
      </Stack>
    )
  })

ThePrinciplesDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

export const DZ_Principle = ({ data }) => {
  const theme = useTheme()
  const isGtMD = useMediaQuery(theme.breakpoints.up('md'))

  return isGtMD ? (
    <ThePrinciplesDesktop data={data} />
  ) : (
    <ThePrinciplesMobile data={data} />
  )
}

DZ_Principle.propTypes = {
  data: PropTypes.array.isRequired
}
