import { Stack, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Typo20RTF, Typo32 } from '../../'

const TheExecutionMobile = ({ data }) => {
  return (
    <Stack alignItems={'center'} sx={{ background: '#ececed', width: 1 }}>
      <Typo32 sx={{ my: 5 }}>Die Umsetzung</Typo32>

      <Stack sx={{ px: 2, mb: 5 }}>
        {data.map((item, key) => {
          return <Typo20RTF key={key}>{item.text}</Typo20RTF>
        })}
      </Stack>
    </Stack>
  )
}

TheExecutionMobile.propTypes = {
  data: PropTypes.array.isRequired
}

const TheExecutionDesktop = ({ data }) => {
  return (
    <Stack alignItems={'center'} sx={{ background: '#ececed', width: 1 }}>
      <Typo32 sx={{ my: 5 }}>Die Umsetzung</Typo32>

      <Stack direction={'row'} spacing={10} sx={{ maxWidth: 1300, mb: 5 }}>
        {data.map((item, key) => {
          return (
            <Typo20RTF key={key} sx={{ maxWidth: 760 }}>
              {item.text}
            </Typo20RTF>
          )
        })}
      </Stack>
    </Stack>
  )
}

TheExecutionDesktop.propTypes = {
  data: PropTypes.array.isRequired
}

export const TheExecution = ({ data }) => {
  const theme = useTheme()
  const isGtLG = useMediaQuery(theme.breakpoints.up('lg'))

  return isGtLG ? (
    <TheExecutionDesktop data={data} />
  ) : (
    <TheExecutionMobile data={data} />
  )
}

TheExecution.propTypes = {
  data: PropTypes.array.isRequired
}
