import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const SVG_Expand = ({ width, height }) => {
  const theme = useTheme()
  const { primary } = theme.palette

  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <linearGradient
          id={'linear-gradient'}
          x1={1}
          x2={0.152}
          y2={1}
          gradientUnits={'objectBoundingBox'}
        >
          <stop offset={0} stopColor={primary.light} />
          <stop offset={0.5} stopColor={primary.main} />
          <stop offset={1} stopColor={primary.dark} />
        </linearGradient>
      </defs>

      <path
        d={
          'M-6564.639,55.3l-14.169,14.148-9.193-9.18L-6564.7,37l.063.063.063-.063,23.3,23.265-9.193,9.18Z'
        }
        transform={'translate(6588.501 -36.501)'}
        stroke={'rgba(0,0,0,0)'}
        strokeLinejoin={'round'}
        strokeMiterlimit={10}
        strokeWidth={1}
        fill={'url(#linear-gradient)'}
      />
    </svg>
  )
}

SVG_Expand.defaultProps = {
  width: 47.722,
  height: 33.445
}

SVG_Expand.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}
